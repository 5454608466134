import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled, { css, Keyframes, keyframes, StyledProps } from 'styled-components';

export type AnimationProps = StyledProps<{
  percentageCovered: number;
  radius: number;
}>;

const sharedDonutStyling = css`
  fill: transparent;
  stroke-width: 1.5;
`;

const segmentAnimation = (filled: number): Keyframes => keyframes`
  0% {
    stroke-dasharray: 0 100;
  }
  100% {
    stroke-dasharray: ${filled} ${100 - filled};
  }
`;

export const FullSizedSvg = styled.svg`
  width: 100%;
  height: 100%;
`;

export const DonutRing = styled.circle`
  ${sharedDonutStyling}
  stroke: ${colors.neutral04};
`;

export const DonutSegment = styled.circle<AnimationProps>`
  ${sharedDonutStyling};
  stroke: ${colors.core01};
  stroke-dashoffset: 25;
  ${({ percentageCovered }) => css`
    stroke-dasharray: ${percentageCovered} ${100 - percentageCovered};
    animation: 1s ${segmentAnimation(percentageCovered)} forwards;
  `}
`;

export const DonutSegmentBasic = styled.circle<AnimationProps>`
  ${sharedDonutStyling};
  stroke: ${colors.neutral01};
  stroke-dashoffset: 25;
  ${({ percentageCovered }) => css`
    stroke-dasharray: ${percentageCovered} ${100 - percentageCovered};
    animation: 1s ${segmentAnimation(percentageCovered)} forwards;
  `}
`;

/* Span styling styles spans added in wrapDonutChartPlaceholdersWithSpan. */
export const ChartText = styled(RichText)`
  ${fonts.paragraph};

  & {
    font-size: 1.125rem;
    font-weight: normal;
    color: ${colors.neutral01};
  }
  span {
    font-weight: bolder;
    color: ${colors.core01};
  }
`;

export const DonutChartContainer = styled.div`
  width: 200px;
  height: 200px;
  margin-top: ${spacing(2)};
  position: relative;
`;

export const ChartTextContainer = styled.div`
  width: 100%;
  padding: ${spacing(4)};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;
