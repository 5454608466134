import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import { graphql, useStaticQuery } from 'gatsby';
import React, { MouseEventHandler, useReducer } from 'react';
import DiscountPanel from 'components/DiscountPanel';
import { PetType, ProductFeature } from 'components/QuoteSummary/ProductFeaturesTable';
import TreatmentCostModal from 'components/TreatmentCostModal';
import { useValidQuoteSummaryOffers } from 'helpers/discountHelpers';
import { PageTitle, trackModalOpen } from 'helpers/eventTracking';
import { formatInPoundsWithSign } from 'helpers/poundsHelpers';
import { Product } from 'helpers/productHelpers';
import { CsCoverLevelOfferInformation } from 'types/contentStack';
import AccidentBasicProductSummarySection from './AccidentBasicProductSummarySection';
import ClassicPremierProductSummarySection from './ClassicPremierProductSummarySection';
import ProductFeatureSection from './ProductFeatureSection';
import TitleBanner from './TitleBanner';
import VetFeeLimitSection from './VetFeeLimitSection';
import { StyledGrid } from './styles';

type CsSummaryOfCover = {
  summary_id: string;
  summary_heading: {
    heading: string;
    subheading: string;
  };
  // Do we need cover_summary_text - not used for other products, just accidents only
  cover_summary_text: string;
  vet_fee_limits: {
    limit_text: string;
    default_fee_limit?: string;
    for_each_text: string;
    or_text?: string;
    cover_duration_text: string;
    treatment_cost_link: string;
  };
  feature_list_heading: string;
};

type SummaryOfCoverCsProps = {
  allCsPetSummaryOfCover: {
    nodes: CsSummaryOfCover[];
  };
  csPetSummaryOfCoverInstallmentInfo: {
    annual_installment_text: string;
    monthly_installment_text: string;
    switch_to_annual_link_text: string;
    switch_to_monthly_link_text: string;
  };
  csPetSummaryOfCoverChecklist: {
    product_feature: ProductFeature[];
  };
};

type SummaryOfCoverProps = {
  isAnnualPayment: boolean;
  paymentOnClick: MouseEventHandler;
  treatmentCostOnClick: MouseEventHandler;
  product: Product;
  insuredPetTypes: PetType;
  classicVetFeeLimit?: number;
  premierVetFeeLimit?: number;
  basicVetFeeLimit?: number;
  productSummaryRef: React.RefObject<HTMLDivElement>;
  pageTitle: PageTitle;
} & ComponentProps;

export const query = graphql`
  query {
    allCsPetSummaryOfCover {
      nodes {
        summary_id
        summary_heading {
          heading
          subheading
        }
        cover_summary_text
        vet_fee_limits {
          limit_text
          default_fee_limit
          for_each_text
          or_text
          cover_duration_text
          treatment_cost_link
        }
        feature_list_heading
      }
    }
    csPetSummaryOfCoverInstallmentInfo {
      annual_installment_text
      monthly_installment_text
      switch_to_annual_link_text
      switch_to_monthly_link_text
    }
    csPetSummaryOfCoverChecklist {
      product_feature {
        display_on_products_selector {
          product_selector
        }
        included_with_products_selector {
          product_selector
        }
        applicable_pets
        modal {
          modal_id
        }
        feature_text
      }
    }
  }
`;

const SummaryOfCover: React.FC<SummaryOfCoverProps> = ({
  isAnnualPayment,
  paymentOnClick,
  treatmentCostOnClick,
  product,
  insuredPetTypes,
  classicVetFeeLimit,
  premierVetFeeLimit,
  basicVetFeeLimit,
  productSummaryRef,
  pageTitle,
  ...props
}) => {
  const {
    csPetSummaryOfCoverInstallmentInfo: {
      annual_installment_text,
      monthly_installment_text,
    },
    csPetSummaryOfCoverChecklist: { product_feature },
    allCsPetSummaryOfCover,
  } = useStaticQuery<SummaryOfCoverCsProps>(query);

  const offers = useValidQuoteSummaryOffers();

  const getCoverSummaryData = (currentProduct: Product): CsSummaryOfCover | undefined => {
    return allCsPetSummaryOfCover.nodes.find((n) => n.summary_id === currentProduct);
  };
  const coverSummaryData = getCoverSummaryData(product);
  const getVetFeeLimit = (): number | undefined => {
    switch (product) {
      case Product.Basic:
        return basicVetFeeLimit;
      case Product.Classic:
        return classicVetFeeLimit;
      case Product.Premier:
        return premierVetFeeLimit;
      default:
        return undefined;
    }
  };

  const vetFeeLimitStringWithPoundSigns =
    formatInPoundsWithSign(getVetFeeLimit()) ||
    coverSummaryData?.vet_fee_limits.default_fee_limit ||
    '';

  const [showTreatmentCostModal, toggleTreatmentCostModal] = useReducer(
    (state) => !state,
    false
  );

  const treatmentCostModalOnClick = (): void => {
    toggleTreatmentCostModal();
    trackModalOpen('treatmentCosts');
  };

  return (
    <StyledGrid alignLeft>
      {coverSummaryData && (
        <GridItem
          desktop={10}
          tabletLandscape={10}
          tabletPortrait={8}
          {...componentProps(props)}>
          <TitleBanner
            heading={coverSummaryData.summary_heading.heading}
            pageTitle={pageTitle}
          />
          {(product === Product.Classic || product === Product.Premier) && (
            <div ref={productSummaryRef}>
              <ClassicPremierProductSummarySection
                isAnnualPayment={isAnnualPayment}
                paymentOnClick={paymentOnClick}
                product={product}
                vetFeeLimit={getVetFeeLimit()}
                treatmentCostOnClick={treatmentCostModalOnClick}
                insuredPetTypes={insuredPetTypes}
              />
            </div>
          )}
          {product === Product.Basic && (
            <>
              <div ref={productSummaryRef}>
                <AccidentBasicProductSummarySection
                  isAnnualPayment={isAnnualPayment}
                  paymentTypeText={
                    isAnnualPayment ? annual_installment_text : monthly_installment_text
                  }
                  paymentOnClick={paymentOnClick}
                  product={product}
                  vetFeeLimit={getVetFeeLimit()}
                  insuredPetTypes={insuredPetTypes}
                />
              </div>
              <VetFeeLimitSection
                vetFeeLimitText={coverSummaryData.vet_fee_limits.limit_text}
                vetFeeLimit={vetFeeLimitStringWithPoundSigns}
                forEachText={coverSummaryData.vet_fee_limits.for_each_text}
                optionalOrText={coverSummaryData.vet_fee_limits.or_text}
                coverDurationText={coverSummaryData.vet_fee_limits.cover_duration_text}
                treatmentCostLink={
                  product === Product.Basic
                    ? coverSummaryData.vet_fee_limits.treatment_cost_link
                    : undefined
                }
                treatmentCostOnClick={treatmentCostOnClick}
                pageTitle={pageTitle}
              />
            </>
          )}
          {offers &&
            offers.map((offer: CsCoverLevelOfferInformation) => (
              <DiscountPanel
                offerPanel={offer}
                pageTitle={PageTitle.QuoteSummary}
                key={offer.offer_start_date}
              />
            ))}
          <ProductFeatureSection
            sectionHeading={coverSummaryData.feature_list_heading}
            features={product_feature}
            product={product}
            insuredPetTypes={insuredPetTypes}
            pageTitle={pageTitle}
            centerHeadingOnMobile={
              product === Product.Classic || product === Product.Premier
            }
            vetFeeLimit={getVetFeeLimit()}
          />
        </GridItem>
      )}
      {showTreatmentCostModal && (
        <TreatmentCostModal
          onClose={toggleTreatmentCostModal}
          pageTitle={PageTitle.QuoteSummary}
        />
      )}
    </StyledGrid>
  );
};

export default SummaryOfCover;
