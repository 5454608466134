import { boxShadow } from '@rsa-digital/evo-shared-components/commonStyles/boxStyles';
import { Divider } from '@rsa-digital/evo-shared-components/components/Divider/SimpleDivider/styles';
import RadioInput from '@rsa-digital/evo-shared-components/components/Form/RadioInput';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { InfoCard } from '@rsa-digital/evo-shared-components/components/InfoCard';
import { Heading } from '@rsa-digital/evo-shared-components/components/InfoCard/InfoCard/styles';
import SimplePanel from '@rsa-digital/evo-shared-components/components/Panel/SimplePanel';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import QuestionField from 'components/QuestionField';
import { avoidBreakOnPrint } from 'helpers/cssPrintHelpers';

export const StyledHeadingAsParagraph = styled.h2`
  ${fonts.paragraphLarge}
  margin-top: ${spacing(-2)};
  margin-bottom: ${spacing(3)};

  ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(4)};
  `}
`;

export const StyledParagraphNoMargin = styled.p`
  ${fonts.paragraphLarge};
  margin: 0 0 ${spacing(3)};
`;

export const StyledParagraph = styled.p`
  ${fonts.paragraphLarge};
  margin-top: ${spacing(2)};
  margin-bottom: ${spacing(3)};

  ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(4)};
  `}
`;

export const StyledQuestionField = styled(QuestionField)`
  ${avoidBreakOnPrint}
`;

export const StyledGrid = styled(Grid)`
  ${avoidBreakOnPrint};
`;

export const StyledHeading = styled.h3`
  ${fonts.headingMedium}
  margin: ${spacing(4)} 0;
`;

export const LargeTextParagraphWithSmallMargins = styled.p`
  ${fonts.paragraphLarge};
  margin-top: ${spacing(1)};
  margin-bottom: ${spacing(2)};

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(4)};
  `}

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(2)};
  `}
`;

export const ParagraphWithSmallMargins = styled.p`
  ${fonts.paragraphLarge};
  margin: ${spacing(2)} 0;

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(4)};
  `}

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(3)};
  `}
`;

export const StyledGridItem = styled(GridItem)`
  margin-right: 0;
  margin-bottom: ${spacing(4)};
`;

export const CoverSummaryHeading = styled.h3`
  ${fonts.headingMedium};
  margin-bottom: ${spacing(3)};

  ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(4)};
  `}
`;

export const PriceBannerSpace = styled.div`
  height: ${spacing(12)};

  ${mediaQuery.tabletPortrait`
    height: ${spacing(8)};
  `}
`;

export const InfoCardWithMargin = styled(InfoCard)`
  margin: ${spacing(-1)} 0 ${spacing(3)};

  padding-top: ${spacing(3)};
  padding-bottom: ${spacing(3)};

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(4)};
  `}

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(-2)};
  `}

  ${mediaQuery.tabletLandscape`
    padding-top: ${spacing(4)};
    padding-bottom: ${spacing(4)};
  `}

  ${Heading} {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const PaymentOverviewSection = styled.section`
  border-radius: ${({ theme }) => theme.cardRadius};
  box-shadow: ${boxShadow};

  ${avoidBreakOnPrint};
`;

export const StyledPanel = styled(SimplePanel)<{ children?: React.ReactNode }>`
  padding: ${spacing(4)} ${spacing(2)} 0;
  margin-bottom: ${spacing(4)};

  ${mediaQuery.tabletPortrait`
  padding: ${spacing(4)} ${spacing(4)} 0;
`}
`;

export const VetFeeLimitInfoPanel = styled(SimplePanel)<{ children?: React.ReactNode }>`
  margin-bottom: ${spacing(5)};
`;

export const DividerWithTopAndBottomMargin = styled(Divider)`
  margin-top: ${spacing(3)};
  margin-bottom: ${spacing(3)};
`;

export const IconWrapper = styled.div`
  margin-right: ${spacing(2)};
`;

export const GridNoWrap = styled.div`
  display: flex;
  wrap: no-wrap;
`;

export const QuestionFieldReducedBottomMargin = styled(QuestionField)`
  margin-bottom: ${spacing(2)};
`;

export const NoLeftRightPaddingRadioInput = styled(RadioInput)`
  label {
    padding-left: 0;
    padding-right: 0;
  }
`;
