import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { lightFontWeight } from '@rsa-digital/evo-shared-components/theme/MoreThan/fonts';
import styled from 'styled-components';

export const StyledSpanLarge = styled.span`
  ${fonts.headingXSmall}

  ${mediaQuery.tabletPortrait`
    ${fonts.headingSmall}
  `}

  & {
    color: ${colors.core01};
  }
  margin: ${spacing(1)} 0;
  display: block;
`;

export const LightRichText = styled(RichText)`
  p {
    font-weight: ${lightFontWeight};
  }
`;
