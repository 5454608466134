import { Divider } from '@rsa-digital/evo-shared-components/components/Divider/SimpleDivider/styles';
import { InfoWrapper } from '@rsa-digital/evo-shared-components/components/Form/Field/FieldInfo/styles';
import SegmentedSelector from '@rsa-digital/evo-shared-components/components/Form/SegmentedSelector';
import SimplePanel from '@rsa-digital/evo-shared-components/components/Panel/SimplePanel';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import QuestionField from 'components/QuestionField';
import QuestionFieldWithOptionalIconAndHeading from 'components/QuestionFieldWithOptionalIconAndHeading';
import RichTextWithModal from 'components/RichTextWithModal';
import Table from 'components/Table';
import { avoidBreakOnPrint } from 'helpers/cssPrintHelpers';

export const ExcessSectionWrapper = styled.div`
  margin-bottom: ${spacing(6)};

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(4)};
  `}

  ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(6)};
  `}
`;

export const RichTextModalWithModalAndBottomMargin = styled(RichTextWithModal)`
  margin-bottom: ${spacing(4)};
`;

export const RichTextModalWithModalAndBottomMarginMobile = styled(
  RichTextModalWithModalAndBottomMargin
)`
  ${mediaQuery.tabletPortrait`
    display: none;
  `}
`;

export const RichTextModalWithModalAndBottomMarginNonMobile = styled(
  RichTextModalWithModalAndBottomMargin
)`
  display: none;
  ${mediaQuery.tabletPortrait`
    display: block;
  `}
`;

export const PetExcessPanel = styled(SimplePanel)<{ children?: React.ReactNode }>`
  padding: ${spacing(2)} ${spacing(4)} ${spacing(4)};
  margin-bottom: ${spacing(4)};
`;

export const TwentyPercentExcessQuestionPanel = styled(SimplePanel)`
  padding: ${spacing(3)} ${spacing(2)} 0;
  margin: ${spacing(2)} 0 ${spacing(4)};

  ${mediaQuery.tabletPortrait`
    padding: ${spacing(3)} ${spacing(4)} 0;
  `}

  ${mediaQuery.tabletLandscape`
    margin-left: ${spacing(4)};
    margin-right: ${spacing(4)};
  `}

  ${avoidBreakOnPrint}
`;

export const ChooseVoluntaryExcessQuestionField = styled(QuestionField)`
  label:first-child {
    font-size: 1rem;
  }

  ${SegmentedSelector} label {
    & {
      font-weight: normal;
    }
  }
  margin-bottom: ${spacing(3)};
`;

export const DividerWithTopAndBottomMargin = styled(Divider)`
  margin-top: ${spacing(3)};
  margin-bottom: ${spacing(3)};
`;

export const DividerWithNarrowMargin = styled(Divider)`
  background: ${colors.neutral01};
  margin-top: ${spacing(1)};
  margin-bottom: ${spacing(1)};
`;

export const TwentyPercentExcessQuestionField = styled(
  QuestionFieldWithOptionalIconAndHeading
)`
  ${InfoWrapper} {
    margin-bottom: ${spacing(1.5)};
  }
  margin-top: ${spacing(2)};
`;

export const ExampleClaimTable = styled(Table)`
  display: table;

  margin-bottom: ${spacing(4)};

  th,
  td {
    padding: ${spacing(2)};
  }
`;

export const MobileOnlyWrapper = styled.div`
  ${mediaQuery.tabletPortrait`
  display: none;
`}
`;

export const NonMobileWrapper = styled.div`
  display: none;
  ${mediaQuery.tabletPortrait`
  display: block;
`}
`;

export const IconWrapper = styled.div`
  margin-right: ${spacing(2)};
`;

export const Heading = styled.h3`
  ${fonts.headingSmall};
  margin: 0;
`;

export const VoluntaryExcessIllustrationPanel = styled(SimplePanel)<{
  children?: React.ReactNode;
}>`
  background: ${colors.neutral06};
  border: 1px solid ${colors.neutral02};
  margin: ${spacing(4)} 0;
  padding: ${spacing(4)};
  display: flex;
  flex-direction: column;
`;

export const VoluntaryExcessIllustrationHeading = styled(RichText)`
  h3 {
    margin: 0;
  }
`;

export const VoluntaryExcessIllustrationContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${mediaQuery.tabletPortrait`
    flex-direction: row;
  `}
`;

export const VoluntaryExcessIllustrationClaimWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: ${spacing(4)};
  width: 100%;
  ${mediaQuery.tabletPortrait`
    width: 45%;
  `}
`;

export const StyledRichText = styled(RichTextWithModal)`
  ${RichText} {
    p {
      ${fonts.paragraphLarge}
      margin-top: ${spacing(1)};
    }

    & > *:first-child {
      margin-top: 0;
    }
  }
`;
