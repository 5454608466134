import { Grid } from '@rsa-digital/evo-shared-components/components/Grid';
import { ButtonLink } from '@rsa-digital/evo-shared-components/components/Link';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled, { css } from 'styled-components';
import { avoidBreakOnPrint } from 'helpers/cssPrintHelpers';

export const StyledGrid = styled(Grid)`
  ${avoidBreakOnPrint};
`;

export const sharedSectionStyling = css`
  text-align: left;
  width: 100%;
`;

export const SummaryOfCoverSection = styled.div`
  ${sharedSectionStyling};
  padding: ${spacing(2)} ${spacing(2)};
  border: 1px solid ${colors.neutral04};
  border-top: none;
  border-bottom: none;
  page-break-inside: avoid;

  ${mediaQuery.tabletPortrait`
    padding: ${spacing(2)} ${spacing(3)};
  `}

  ${mediaQuery.tabletLandscape`
    padding: ${spacing(2)} ${spacing(4)};
  `}
`;

export const StyledSpan = styled.span`
  ${fonts.paragraph};
  display: block;
  & {
    font-weight: bold;
  }
`;

export const StyledButtonLink = styled(ButtonLink)<{ children?: React.ReactNode }>`
  ${fonts.paragraph}
  & {
    color: ${colors.core01};
    font-weight: normal;
  }
  text-decoration: underline;
  margin-top: ${spacing(1)};

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(2)};
  `}
`;

export const ButtonLinkContainer = styled.div`
  display: flex;
  margin-bottom: ${spacing(2)};
`;
