import { css } from 'styled-components';

export const hideOnPrint = css`
  @media print {
    display: none;
  }
`;

export const avoidBreakOnPrint = css`
  @media print {
    break-inside: avoid;
  }
`;
