import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import React from 'react';
import ProductFeaturesTable, {
  PetType,
  ProductFeature,
} from 'components/QuoteSummary/ProductFeaturesTable';
import { PageTitle } from 'helpers/eventTracking';
import { Product } from 'helpers/productHelpers';
import { ClassicCoverFeeLimit, PremierCoverFeeLimit } from 'state/formData/quoteOptions';
import {
  ChecklistSectionHeading,
  DivWithLeftMarginTablet,
  ProductFeatureSectionContainer,
} from './styles';

type ProductFeatureSectionProps = {
  sectionHeading: string;
  features: ProductFeature[];
  product: Product;
  insuredPetTypes: PetType;
  pageTitle: PageTitle;
  centerHeadingOnMobile: boolean;
  vetFeeLimit: ClassicCoverFeeLimit | PremierCoverFeeLimit | undefined;
};

const ProductFeatureSection: React.FC<ProductFeatureSectionProps> = ({
  sectionHeading,
  features,
  product,
  insuredPetTypes,
  pageTitle,
  centerHeadingOnMobile,
  vetFeeLimit,
}) => (
  <ProductFeatureSectionContainer>
    <DivWithLeftMarginTablet>
      <ChecklistSectionHeading centerContentsOnMobile={centerHeadingOnMobile}>
        {sectionHeading}
      </ChecklistSectionHeading>
      <GridItem desktop={6} tabletLandscape={6} tabletPortrait={6}>
        <ProductFeaturesTable
          features={features}
          product={product}
          insuredPetTypes={insuredPetTypes}
          pageTitle={pageTitle}
          vetFeeLimit={vetFeeLimit}
        />
      </GridItem>
    </DivWithLeftMarginTablet>
  </ProductFeatureSectionContainer>
);

export default ProductFeatureSection;
