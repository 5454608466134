import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import { sharedSectionStyling } from '../styles';

export const TitleBannerConainer = styled.div`
  ${sharedSectionStyling};
  background-color: ${colors.core01};
  padding: ${spacing(2)} ${spacing(3.5)};
  position: relative;
  border-radius: ${spacing(1)} ${spacing(1)} 0 0;

  ${mediaQuery.tabletPortrait`
    padding: ${spacing(3)} ${spacing(4.5)};
  `}
  
  ${mediaQuery.tabletLandscape`
    padding: ${spacing(2)} ${spacing(6)};
  `}

  ${mediaQuery.desktop`
    padding: ${spacing(2)} ${spacing(6.5)};
  `}
`;

export const TitleText = styled.p`
  ${fonts.headingSmall};
  & {
    color: ${colors.neutral08};
  }

  margin: 0;
`;

export const ColouredRichText = styled(RichText)`
  color: ${colors.neutral08};
`;
