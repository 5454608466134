import throttle from 'lodash/throttle';
import { useEffect } from 'react';

const useScrollCallback = (callback: () => void, updateRateMs?: number): void => {
  useEffect(() => {
    const handleScroll = throttle(callback, updateRateMs);

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return (): void => window.removeEventListener('scroll', handleScroll);
  }, [callback, updateRateMs]);
};

export default useScrollCallback;
