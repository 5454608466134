import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { generateKeyForElement } from '@rsa-digital/evo-shared-components/helpers/elementKeyHelper';
import { QuoteResponsePetInfo } from 'apiHelpers/quote/quoteResponse';
import { isPetAlreadyAgeNineForCover } from 'businessLogic/petAge';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import RichTextWithModal from 'components/RichTextWithModal';
import { PageTitle } from 'helpers/eventTracking';
import { petPlaceholders } from 'helpers/placeholders/petPlaceholders';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import { replacePlaceholdersRichText } from 'helpers/placeholders/replaceCsPlaceholders';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { ExcessStatementWrapper, StyledGrid } from './styles';
import { useSummaryText } from './useSummaryText';

type CsSummaryOfNeedsTextSection = {
  csPetQuoteSummaryOfYourNeeds: {
    text_summary: {
      per_pet_excess_statement_basic_classic_premier_under_9yrs: string;
      per_pet_excess_statement_basic_classic_premier_over_9yrs: string;
    };
  };
};

const query = graphql`
  query {
    csPetQuoteSummaryOfYourNeeds {
      text_summary {
        per_pet_excess_statement_basic_classic_premier_under_9yrs
        per_pet_excess_statement_basic_classic_premier_over_9yrs
      }
    }
  }
`;

const SummaryOfYourNeedsTextSection: React.FC<{ pageTitle: PageTitle }> = ({
  pageTitle,
}) => {
  const {
    per_pet_excess_statement_basic_classic_premier_under_9yrs,
    per_pet_excess_statement_basic_classic_premier_over_9yrs,
  } = useStaticQuery<CsSummaryOfNeedsTextSection>(
    query
  ).csPetQuoteSummaryOfYourNeeds.text_summary;

  const quote = useCurrentQuote();

  const summaryText = useSummaryText();

  const substituteQuotePlaceholders = replacePlaceholdersRichText(
    quotePlaceholders,
    quote
  );

  const getExcessStatement = (pet: QuoteResponsePetInfo): string => {
    if (
      quote.policyInfo &&
      isPetAlreadyAgeNineForCover(pet.dob, quote.policyInfo?.coverStartDate)
    ) {
      return per_pet_excess_statement_basic_classic_premier_over_9yrs;
    }
    return per_pet_excess_statement_basic_classic_premier_under_9yrs;
  };

  const substituteExcessStatementPetPlaceholders = (
    pet: QuoteResponsePetInfo
  ): string => {
    const excessStatement = getExcessStatement(pet);
    return replacePlaceholdersRichText(petPlaceholders, pet)(excessStatement);
  };

  return (
    <>
      {summaryText && (
        <StyledGrid alignLeft>
          <GridItem>
            <RichTextWithModal
              pageTitle={pageTitle}
              html={substituteQuotePlaceholders(summaryText?.text_before_statement)}
            />
            <ExcessStatementWrapper>
              {quote.petInfos?.map((pet) => (
                <RichTextWithModal
                  pageTitle={pageTitle}
                  key={generateKeyForElement(pet)}
                  html={substituteExcessStatementPetPlaceholders(pet)}
                />
              ))}
            </ExcessStatementWrapper>
            <RichTextWithModal
              pageTitle={pageTitle}
              html={substituteQuotePlaceholders(summaryText?.text_after_statement)}
            />
          </GridItem>
        </StyledGrid>
      )}
    </>
  );
};

export default SummaryOfYourNeedsTextSection;
