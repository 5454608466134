import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const Heading = styled.h3`
  ${fonts.headingSmall};
  margin: 0;
  grid-column: 2;
`;

export const IconWrapper = styled.div`
  margin-right: ${spacing(2)};
  grid-column: 1/2;
`;

export const GridItemFlex = styled(GridItem)`
  display: grid;
  grid-template-columns: min-content auto;
  ${mediaQuery.tabletPortrait`
    margin-left: ${spacing(0)};
`}
`;

export const RichTextWithMargins = styled(RichText)`
  margin-top: ${spacing(2)};
  margin-bottom: ${spacing(2)};
`;

export const LeftAlignTextOnMobile = styled.div`
  grid-column: 1 / span 2;
  ${mediaQuery.tabletPortrait`
    grid-column: 2;
`}
`;
