import InfoBox from '@rsa-digital/evo-shared-components/components/InfoBox';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const StyledInfoBox = styled(InfoBox)`
  && {
    margin-top: ${spacing(5)};
    margin-bottom: 0;

    ${mediaQuery.tabletPortrait`
      margin-top: ${spacing(4)};
    `}
  }
`;
