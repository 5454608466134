import theme from '@rsa-digital/evo-shared-components/theme/MoreThan';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { trackDownloadClick } from 'helpers/eventTracking';
import { usePolicyDocumentLink } from 'helpers/usePolicyDocumentLink';
import { QuoteOptions } from 'state/formData/quoteOptions';
import { StyledDocumentDownloadLink } from './styles';

export type Link = {
  url: string;
  text: string;
  filesize: string;
  screenreader_text: string | null;
  open_in_new_tab: boolean;
};

type PolicyDocumentsContent = {
  csPetPolicyDocuments: {
    essential_information: Link;
    policy_booklet: Link;
  };
};

const query = graphql`
  query {
    csPetPolicyDocuments {
      essential_information {
        url
        text
        filesize
        open_in_new_tab
      }
      policy_booklet {
        url
        text
        filesize
        open_in_new_tab
      }
    }
  }
`;

type PolicyDocumentsProps = {
  quoteOptions: QuoteOptions;
};

const PolicyDocuments: React.FC<PolicyDocumentsProps> = ({ quoteOptions }) => {
  const pageContent = useStaticQuery<PolicyDocumentsContent>(query).csPetPolicyDocuments;

  const productInformationLink = usePolicyDocumentLink(quoteOptions);

  return (
    <div>
      {productInformationLink && (
        <StyledDocumentDownloadLink
          documentName={productInformationLink.text}
          documentUrl={productInformationLink.url}
          fileSize={Number(productInformationLink.filesize)}
          iconName={theme.buttonIcons?.downloadGeneric}
          iconSize="small"
          onClick={() =>
            trackDownloadClick('Product information document', productInformationLink.url)
          }
          openInNewTab={productInformationLink.open_in_new_tab}
        />
      )}
      <StyledDocumentDownloadLink
        documentName={pageContent.essential_information.text}
        documentUrl={pageContent.essential_information.url}
        fileSize={Number(pageContent.essential_information.filesize)}
        iconName={theme.buttonIcons?.downloadGeneric}
        iconSize="small"
        onClick={() =>
          trackDownloadClick(
            'Essential information document',
            pageContent.essential_information.url
          )
        }
        openInNewTab={pageContent.essential_information.open_in_new_tab}
      />
      <StyledDocumentDownloadLink
        documentName={pageContent.policy_booklet.text}
        documentUrl={pageContent.policy_booklet.url}
        fileSize={Number(pageContent.policy_booklet.filesize)}
        iconName={theme.buttonIcons?.downloadGeneric}
        iconSize="small"
        onClick={() =>
          trackDownloadClick('Policy booklet document', pageContent.policy_booklet.url)
        }
        openInNewTab={pageContent.policy_booklet.open_in_new_tab}
      />
    </div>
  );
};

export default PolicyDocuments;
