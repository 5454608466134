import { Grid } from '@rsa-digital/evo-shared-components/components/Grid';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import { avoidBreakOnPrint } from 'helpers/cssPrintHelpers';

export const DonutChartWrapper = styled.div`
  display: flex;
  justify-content: center;
  order: 2;
  text-align: -webkit-center;
  margin-bottom: ${spacing(2)};
  margin-top: ${spacing(1)};

  ${mediaQuery.tabletPortrait`
    order: 5;
    display: block;
    margin-top: ${spacing(2.5)};
    margin-bottom: 0;
  `}

  ${mediaQuery.tabletLandscape`
    padding-left: ${spacing(4)};
  `}
`;

export const GridWithTopMargin = styled(Grid)`
  margin: ${spacing(-1)};
  ${mediaQuery.tabletPortrait`
    margin: ${spacing(0.5)};
  `}
  ${avoidBreakOnPrint};
`;

/* Grid display setting below allows content ordering, meaning Help Text can be shown below 'typical treatment costs' link on mobile */
export const ClaimsCoveredHelpText = styled.div<{
  centerContentsOnMobile: boolean;
}>`
  text-align: ${(p) => (p.centerContentsOnMobile ? 'center' : 'left')};
  margin-left: ${(p) => (p.centerContentsOnMobile ? 0 : spacing(1))};
  display: grid;

  ${mediaQuery.tabletPortrait`
    text-align: left;
    margin-left: ${spacing(1)}
  `}

  ${mediaQuery.tabletLandscape`
    margin-left: ${spacing(2)};
  `}

  button {
    width: 100%;
    ${mediaQuery.tabletPortrait`
      text-align: left;
    `}
  }
`;

export const HelpText = styled.p`
  order: 1;
  margin: ${spacing(2)} 0 ${spacing(1)};
  ${fonts.paragraph};

  && {
    font-size: 1rem; // 16px
    font-weight: 300;

    ${mediaQuery.tabletLandscape`
      font-size: 0.875rem; // 14px
    `}
  }

  // width 100% is needed so the text wraps in IE
  width: 100%;

  ${mediaQuery.tabletPortrait`
    order: 0;
    margin: 0;
    margin-top: ${spacing(3)};
  `}
`;
