import { scrollAndFocusInput } from '@rsa-digital/evo-shared-components/helpers/forms/scrollAndFocusError';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import BooleanRadioInput from 'components/BooleanRadioInput';
import QuestionFieldWithOptionalIconAndHeading from 'components/QuestionFieldWithOptionalIconAndHeading';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import { PageTitle } from 'helpers/eventTracking';
import { useCurrentQuote, useUpdateQuoteOptions } from 'helpers/useCurrentQuote';
import useQuestionProcessor from 'helpers/useQuestionProcessor';
import {
  accidentAndIllnessCoverDurationRadioButton,
  premierCoverFeeLimitsRadioButtonId,
} from 'pages/pet/quote-summary';
import { QuoteOptions } from 'state/formData/quoteOptions';
import { CsIcon, CsQuestion } from 'types/contentStack';
import { isBasicProduct } from './helpers';

type SummaryOfYourNeedsAdditionalQuestion = {
  csPetQuoteSummaryOfYourNeeds: {
    additional_question_header: {
      icon: [CsIcon];
      heading: string;
    };
    additional_question_basic: {
      no_button_text: string;
      yes_button_text: string;
      question_details: string;
      question_field: CsQuestion;
    };
    additional_question_cheapest_premier: {
      no_button_text: string;
      yes_button_text: string;
      question_details: string;
      question_field: CsQuestion;
    };
  };
};

type AdditionalQuestionProps = {
  formValidation: {
    getError: FieldFunction<QuoteOptions, string | undefined>;
    showValidation: FieldFunction<QuoteOptions, void>;
  };
};

type AdditionalQuestionFields = {
  no_button_text: string;
  yes_button_text: string;
  question_details: string;
  question_field: CsQuestion;
};

type AdditionalQuestionAttributes = {
  additionalQuestion: AdditionalQuestionFields;
  questionId:
    | 'additionalQuestionBasic'
    | 'additionalQuestionCheapestPremier'
    | 'accidentAndIllnessCoverDuration'
    | 'basicCoverFeeLimit'
    | 'classicCoverFeeLimit'
    | 'premierCoverFeeLimit'
    | 'isAnnualPayment'
    | 'addOnsSelected';
  inputValue: boolean | undefined;
  idOfScrolledToQuestion: string;
};

const query = graphql`
  query {
    csPetQuoteSummaryOfYourNeeds {
      additional_question_header {
        icon {
          icon_code
        }
        heading
      }
      additional_question_basic {
        no_button_text
        question_details
        question_field {
          main_details {
            question_text
            explanatory_text
            tooltip {
              tooltip_text
              tooltip_open
              tooltip_close
            }
            alert_text
          }
        }
        yes_button_text
      }
      additional_question_cheapest_premier {
        no_button_text
        question_details
        yes_button_text
        question_field {
          main_details {
            question_text
            explanatory_text
            tooltip {
              tooltip_text
              tooltip_open
              tooltip_close
            }
            alert_text
          }
        }
      }
    }
  }
`;

const AdditionalQuestion: React.FC<AdditionalQuestionProps> = ({
  formValidation: { getError, showValidation },
}) => {
  const { processQuestion } = useQuestionProcessor(PageTitle.QuoteSummary);
  const quote = useCurrentQuote();
  const updateQuoteOptions = useUpdateQuoteOptions();
  const {
    csPetQuoteSummaryOfYourNeeds: {
      additional_question_header,
      additional_question_basic,
      additional_question_cheapest_premier,
    },
  } = useStaticQuery<SummaryOfYourNeedsAdditionalQuestion>(query);

  const productIsBasic = isBasicProduct(quote.quoteOptions);

  let additionalQuestionAttributes: AdditionalQuestionAttributes;

  if (productIsBasic) {
    additionalQuestionAttributes = {
      additionalQuestion: additional_question_basic,
      questionId: 'additionalQuestionBasic',
      inputValue: quote?.quoteOptions?.additionalQuestionBasic,
      idOfScrolledToQuestion: accidentAndIllnessCoverDurationRadioButton,
    };
  } else {
    // Cheapest Premier
    additionalQuestionAttributes = {
      additionalQuestion: additional_question_cheapest_premier,
      questionId: 'additionalQuestionCheapestPremier',
      inputValue: quote?.quoteOptions?.additionalQuestionCheapestPremier,
      idOfScrolledToQuestion: premierCoverFeeLimitsRadioButtonId,
    };
  }

  const additionalQuestionSectionIcon = unwrapSingleton(additional_question_header.icon)
    ?.icon_code;

  const question = processQuestion(
    additionalQuestionAttributes.additionalQuestion.question_field
  );

  return (
    <QuestionFieldWithOptionalIconAndHeading
      gridItemProps={{
        desktop: 8,
        tabletLandscape: 8,
        tabletPortrait: 8,
        mobile: 4,
      }}
      errorText={getError(additionalQuestionAttributes.questionId)}
      question={{
        questionIcon: additionalQuestionSectionIcon,
        questionHeading: additional_question_header.heading,
        introductoryText:
          additionalQuestionAttributes.additionalQuestion.question_details,
        questionText: question.questionText,
        tooltip: question.tooltip,
        alertText: question.alertText,
        explanatoryText: question.explanatoryText,
      }}>
      <BooleanRadioInput
        id={additionalQuestionAttributes.questionId}
        value={additionalQuestionAttributes.inputValue}
        onChange={(newValue) => {
          updateQuoteOptions({
            [additionalQuestionAttributes.questionId]: newValue,
          });
          if (!newValue) {
            scrollAndFocusInput(additionalQuestionAttributes.idOfScrolledToQuestion);
          }
          if (productIsBasic) {
            showValidation('additionalQuestionBasic');
          }
        }}
        yesLabel={additionalQuestionAttributes.additionalQuestion.yes_button_text}
        noLabel={additionalQuestionAttributes.additionalQuestion.no_button_text}
      />
    </QuestionFieldWithOptionalIconAndHeading>
  );
};

export default AdditionalQuestion;
