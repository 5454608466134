import RadioInput from '@rsa-digital/evo-shared-components/components/Form/RadioInput';
import { Orientation } from '@rsa-digital/evo-shared-components/components/Form/RadioInput/styles';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import Icon from '@rsa-digital/evo-shared-components/components/Icon';
import LoadingOverlayV2 from '@rsa-digital/evo-shared-components/components/LoadingOverlayV2';
import { BorderShadows } from '@rsa-digital/evo-shared-components/components/Panel/types';
import PriceBanner from '@rsa-digital/evo-shared-components/components/PriceBanner';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import useValidation from '@rsa-digital/evo-shared-components/helpers/forms/useValidation';
import {
  getCoverLevelFromQuoteOptions,
  isQuoteOptionSelectionValid,
} from 'apiHelpers/quote/bundleCoverMapping';
import useUpdateQuoteCover from 'apiHelpers/quote/useUpdateQuoteCover';
import { useAddOnsForSelectedProduct } from 'businessLogic/addOns';
import { graphql, navigate } from 'gatsby';
import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import AddAnotherPetModal from 'components/AddAnotherPetModal';
import AggregatorAgreements from 'components/Aggregators/AggregatorAgreements';
import AggregatorSummary from 'components/Aggregators/AggregatorSummary';
import LoadQuoteWrapper from 'components/ApiRequestWrapper/LoadQuoteWrapper';
import FormFooter from 'components/FormFooter';
import HeroBanner from 'components/Header/HeroBanner';
import Layout from 'components/Layout';
import { BannerContainer } from 'components/Layout/styles';
import PricingOverviewPanel from 'components/PricingOverviewPanel';
import { QuoteAndBuyStep } from 'components/ProgressBar';
import PromotionalBox from 'components/PromotionalBox';
import QuestionField from 'components/QuestionField';
import AddOnsSection from 'components/QuoteSummary/AddOnsSection';
import { useAddOnRules } from 'components/QuoteSummary/AddOnsSection/validation';
import AccidentAndIllnessChooseExcessSection from 'components/QuoteSummary/ExcessSection/AccidentAndIllnessChooseExcessSection';
import useTwentyPercentExcessAgreements, {
  TwentyPercentExcessAgreement,
} from 'components/QuoteSummary/ExcessSection/AccidentAndIllnessChooseExcessSection/useTwentyPercentExcessAgreements';
import PolicyDocumentsSection from 'components/QuoteSummary/PolicyDocumentsSection';
import SummaryOfCover from 'components/QuoteSummary/SummaryOfCover';
import AdditionalQuestion from 'components/QuoteSummary/SummaryOfYourNeeds/AdditionalQuestion';
import {
  isBasicProduct,
  isCheapestPremier,
} from 'components/QuoteSummary/SummaryOfYourNeeds/AdditionalQuestion/helpers';
import { useAdditionalQuestionRules } from 'components/QuoteSummary/SummaryOfYourNeeds/AdditionalQuestion/validation';
import InfoBoxSection from 'components/QuoteSummary/SummaryOfYourNeeds/InfoBoxSection';
import SummaryOfYourNeedsTextSection from 'components/QuoteSummary/SummaryOfYourNeeds/SummaryOfYourNeedsTextSection';
import { SessionExpiryOption } from 'components/SessionExpiry/SessionExpiryWrapper';
import TreatmentCostModal from 'components/TreatmentCostModal';
import WhyChooseUsSection from 'components/WhyChooseUsSection';
import SectionHeading from 'forms/SectionHeading';
import { addItem, updateItem } from 'helpers/arrayHelper';
import { ProductId } from 'helpers/businessConstants';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import { useGetDiscountContent } from 'helpers/discountHelpers';
import { trackCheckoutEvent } from 'helpers/ecommerceTracking';
import useDefaultErrorHandling from 'helpers/errorHandling';
import {
  PageTitle,
  trackCoverOptionsSelection,
  trackModalOpen,
  trackSwitchPaymentClick,
  trackTextButtonClick,
  trackVetFeeLimitSelection,
} from 'helpers/eventTracking';
import { scrollAndTrackError } from 'helpers/forms';
import { getPetTypesBeingInsured } from 'helpers/getPetTypesBeingInsured';
import { usePageTracking } from 'helpers/pageTracking';
import {
  CoverPercentagePlaceholderData,
  coverPercentagePlaceholders,
} from 'helpers/placeholders/coverPercentagePlaceholders';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import {
  noOpPlaceholderReplacer,
  replacePlaceholdersPlainText,
  replacePlaceholdersQuestion,
  replacePlaceholdersRichText,
} from 'helpers/placeholders/replaceCsPlaceholders';
import { formatInPoundsWithSign } from 'helpers/poundsHelpers';
import { getProductFromQuoteOptions, Product } from 'helpers/productHelpers';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import { trySaveQuote, useSaveAndEmailQuote } from 'helpers/saveQuoteHelpers';
import { useCurrentQuote, useUpdateQuoteOptions } from 'helpers/useCurrentQuote';
import useQuestionProcessor from 'helpers/useQuestionProcessor';
import useScrollCallback from 'helpers/useScrollCallback';
import { initialPet, usePetsDetails } from 'state/formData/petsDetails';
import {
  AccidentAndIllnessCoverDuration,
  AddOnsSelected,
  BasicCoverFeeLimit,
  ClassicCoverFeeLimit,
  initialAddOnsSelected,
  initialQuoteOptions,
  PremierCoverFeeLimit,
  QuoteOptions,
} from 'state/formData/quoteOptions';
import useReferenceData from 'state/referenceData/useReferenceData';
import { CsHero, CsIcon, CsModal, CsQuestion } from 'types/contentStack';
import {
  DividerWithTopAndBottomMargin,
  GridNoWrap,
  IconWrapper,
  InfoCardWithMargin,
  NoLeftRightPaddingRadioInput,
  PriceBannerSpace,
  QuestionFieldReducedBottomMargin,
  StyledGrid,
  StyledPanel,
  StyledParagraph,
  StyledParagraphNoMargin,
  VetFeeLimitInfoPanel,
} from './styles';
import { useQuoteSummaryDemandsAndNeedsRules } from './validation';

const SCROLL_UPDATE_RATE_MS = 50;
const ECOMMERCE_STEP = 1;

const findingTheRightCoverSectionId = 'finding-the-right-cover-section';
export const accidentAndIllnessCoverDurationRadioButton =
  'accidentAndIllnessCoverDurationRadioButton';
export const premierCoverFeeLimitsRadioButtonId = 'premierCoverFeeLimitsRadioButton';

type InfoPanelProps = {
  icon: [CsIcon];
  heading: string;
  body: string;
};

type QuoteSummaryProps = {
  data: {
    csPetQuoteSummary: {
      meta_title: string;
      hero: CsHero;
      next_button_text: string;
      finding_the_right_cover_section_heading: string;
      basic_accident_and_illness_cover_confirmation: InfoPanelProps;
      classic_accident_and_illness_cover_confirmation: InfoPanelProps;
      premier_accident_and_illness_cover_confirmation: InfoPanelProps;
      classic_vet_fee_limit_help_text: string;
      premier_vet_fee_limit_help_text: string;
      add_ons_section_heading: string;
      add_ons_section_intro: string;
      your_excess_section_heading_premier: string;
      your_excess_section_heading_non_premier: string;
      summary_of_your_needs_section_heading: string;
      successful_save_modal: [CsModal] | [];
      price_banner: {
        monthly_label: string;
        annual_label: string;
      };
      add_another_pet_promo_box: {
        icon: [CsIcon];
        heading: string;
        less_than_three_pets_information: string;
        three_pets_information: string;
        add_another_pet_button_text: string;
        add_another_pet_button_screenreader_text: string;
      };
    };
    csPetQuoteSummaryQuestions: {
      accident_and_illness_cover_duration: CsQuestion;
      accident_and_illness_cover_type_radio_button_options: {
        basic_cover_button_text: string;
        classic_cover_button_text: string;
        premier_cover_button_text: string;
      };
      basic_cover_fee_limits: CsQuestion;
      classic_cover_fee_limits: CsQuestion;
      premier_cover_fee_limits: CsQuestion;
    };
    csPetGlobalConfig: {
      loading_spinner: {
        email_spinner_text: string;
        excess_spinner_text: string;
        continue_spinner_text: string;
      };
    };
  };
};

export const query = graphql`
  query {
    csPetQuoteSummary {
      meta_title
      hero {
        heading
        subheading
      }
      finding_the_right_cover_section_heading
      basic_accident_and_illness_cover_confirmation {
        heading
        body
        icon {
          icon_code
        }
      }
      classic_accident_and_illness_cover_confirmation {
        heading
        body
        icon {
          icon_code
        }
      }
      premier_accident_and_illness_cover_confirmation {
        heading
        body
        icon {
          icon_code
        }
      }
      classic_vet_fee_limit_help_text
      premier_vet_fee_limit_help_text
      add_ons_section_heading
      add_ons_section_intro
      your_excess_section_heading_premier
      your_excess_section_heading_non_premier
      summary_of_your_needs_section_heading
      add_another_pet_promo_box {
        icon {
          icon_code
        }
        heading
        three_pets_information
        less_than_three_pets_information
        add_another_pet_button_text
        add_another_pet_button_screenreader_text
      }
      next_button_text
      successful_save_modal {
        modal_id
      }
      price_banner {
        monthly_label
        annual_label
      }
    }
    csPetQuoteSummaryQuestions {
      accident_and_illness_cover_duration {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
      accident_and_illness_cover_type_radio_button_options {
        basic_cover_button_text
        classic_cover_button_text
        premier_cover_button_text
      }
      basic_cover_fee_limits {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
      classic_cover_fee_limits {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
      premier_cover_fee_limits {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
    }
    csPetGlobalConfig {
      loading_spinner {
        email_spinner_text
        excess_spinner_text
        continue_spinner_text
      }
    }
  }
`;

const QuoteSummary: React.FC<QuoteSummaryProps> = ({
  data: {
    csPetQuoteSummary: {
      meta_title,
      hero,
      next_button_text,
      finding_the_right_cover_section_heading,
      basic_accident_and_illness_cover_confirmation,
      classic_accident_and_illness_cover_confirmation,
      classic_vet_fee_limit_help_text,
      premier_vet_fee_limit_help_text,
      add_ons_section_heading,
      add_ons_section_intro,
      your_excess_section_heading_non_premier,
      your_excess_section_heading_premier,
      summary_of_your_needs_section_heading,
      premier_accident_and_illness_cover_confirmation,
      price_banner,
      add_another_pet_promo_box,
    },
    csPetQuoteSummaryQuestions: {
      accident_and_illness_cover_duration,
      accident_and_illness_cover_type_radio_button_options: {
        basic_cover_button_text,
        classic_cover_button_text,
        premier_cover_button_text,
      },
      basic_cover_fee_limits,
      classic_cover_fee_limits,
      premier_cover_fee_limits,
    },
    csPetGlobalConfig: {
      loading_spinner: { email_spinner_text, excess_spinner_text, continue_spinner_text },
    },
  },
}) => {
  const quote = useCurrentQuote();
  const updateQuoteOptions = useUpdateQuoteOptions();
  const [, displayQuoteSummaryDiscounts] = useGetDiscountContent();

  const {
    twentyPercentExcessAgreements,
    twentyPercentExcessAgreementRules,
    updateTwentyPercentExcessAgreement,
    resetAllTwentyPercentExcessAgreements,
  } = useTwentyPercentExcessAgreements();

  const quoteUpdaters = useUpdateQuoteCover();
  const summaryOfCoverSectionId = 'summary-of-cover-section';
  const addOnsSectionId = 'additional-covers-section';
  const excessSectionId = 'excess-section';
  const summaryOfYourNeedsSectionId = 'summary-of-your-needs-section';

  const quoteOptions = quote?.quoteOptions ?? initialQuoteOptions;

  const accidentAndIllnessIcon = unwrapSingleton(
    basic_accident_and_illness_cover_confirmation.icon
  )?.icon_code;

  const substituteQuotePlaceholders = replacePlaceholdersRichText(
    quotePlaceholders,
    quote
  );

  const updateConfirmation = (obj: InfoPanelProps): InfoPanelProps => {
    const updatedBody = substituteQuotePlaceholders(obj.body);
    return { ...obj, body: updatedBody };
  };

  const basicAccidentCoverConfirmation = updateConfirmation(
    basic_accident_and_illness_cover_confirmation
  );
  const classicAccidentCoverConfirmation = updateConfirmation(
    classic_accident_and_illness_cover_confirmation
  );
  const premierAccidentCoverConfirmation = updateConfirmation(
    premier_accident_and_illness_cover_confirmation
  );

  const {
    accidentAndIllnessCoverDuration,
    basicCoverFeeLimit,
    classicCoverFeeLimit,
    premierCoverFeeLimit,
    isAnnualPayment,
  } = quoteOptions;

  const [showStickyBanner, setShowStickyBanner] = useState<boolean>(false);
  const [isContinueClicked, setIsContinueClicked] = useState<boolean>(false);

  const coverSelectedRef = useRef<HTMLDivElement>(null);

  const totalSelectedPrice = isAnnualPayment
    ? quote.price?.annualPrice.total
    : quote.price?.monthlyPrice.total;

  const petsInHousehold = quote.policyInfo?.numberPetInHousehold
    ? quote.policyInfo?.numberPetInHousehold
    : 1;
  const petsInQuote = quote.petInfos ? quote.petInfos.length : 1;

  const showAddPetPromoBox = petsInHousehold > petsInQuote && petsInQuote < 3;

  const updateScrollCallback = useCallback(() => {
    if (!coverSelectedRef.current) {
      return;
    }
    const summaryBoundingRectangle = coverSelectedRef.current.getBoundingClientRect();
    setShowStickyBanner(summaryBoundingRectangle.bottom < 0);
  }, [coverSelectedRef]);

  useScrollCallback(updateScrollCallback, SCROLL_UPDATE_RATE_MS);

  const { processQuestion } = useQuestionProcessor(PageTitle.QuoteSummary);

  const plainTextPlaceholderReplacer = quote
    ? replacePlaceholdersPlainText(quotePlaceholders, quote, true)
    : noOpPlaceholderReplacer;

  const substituteQuoteQuestionPlaceholders = replacePlaceholdersQuestion(
    plainTextPlaceholderReplacer
  );

  const accidentAndIllnessCoverDurationQuestion = substituteQuoteQuestionPlaceholders(
    processQuestion(accident_and_illness_cover_duration)
  );
  const basicCoverFeeLimitsQuestion = substituteQuoteQuestionPlaceholders(
    processQuestion(basic_cover_fee_limits)
  );
  const classicCoverFeeLimitsQuestion = substituteQuoteQuestionPlaceholders(
    processQuestion(classic_cover_fee_limits)
  );
  const premierCoverFeeLimitsQuestion = substituteQuoteQuestionPlaceholders(
    processQuestion(premier_cover_fee_limits)
  );

  const [showTreatmentCostModal, toggleTreatmentCostModal] = useReducer(
    (state) => !state,
    false
  );

  const product = getProductFromQuoteOptions(quote.quoteOptions);

  const getVetFeeLimit = (): number | undefined => {
    switch (product) {
      case Product.Basic:
        return basicCoverFeeLimit;
      case Product.Classic:
        return classicCoverFeeLimit;
      case Product.Premier:
        return premierCoverFeeLimit;
      default:
        return undefined;
    }
  };

  const [showAddAnotherPetModal, toggleAddAnotherPetModal] = useReducer(
    (state) => !state,
    false
  );

  const paymentOnClick = (): void => {
    trackSwitchPaymentClick(
      PageTitle.QuoteSummary,
      isAnnualPayment ? 'monthly' : 'annually'
    );
    updateQuoteOptions({
      isAnnualPayment: !isAnnualPayment,
    });
  };
  const treatmentCostModalOnClick = (): void => {
    toggleTreatmentCostModal();
    trackModalOpen('treatmentCosts');
  };

  const numberOfPetsInQuote = quote.petInfos?.length || 0;

  const [petsDetails, updatePetsDetails] = usePetsDetails();

  const displayAdditionalQuestion =
    isBasicProduct(quote.quoteOptions) || isCheapestPremier(quote.quoteOptions);

  const defaultErrorHandling = useDefaultErrorHandling();

  const moveNext = async (): Promise<void> => {
    try {
      await trySaveQuote({
        onSuccess: () => navigate(quoteAndBuyRoutes.checkYourDetails),
        quoteUpdaters,
      });
    } catch (err) {
      const error: Error = err as Error;
      defaultErrorHandling(error);
    }
  };

  const {
    saveAndEmailQuote,
    isSaveInProgress,
    savedQuoteConfirmationModal,
  } = useSaveAndEmailQuote(PageTitle.QuoteSummary);

  const formDetails: QuoteOptions &
    AddOnsSelected & { twentyPercentExcessAgreements: TwentyPercentExcessAgreement[] } = {
    ...quote?.quoteOptions,
    ...quote?.quoteOptions.addOnsSelected,
    twentyPercentExcessAgreements,
  };

  const addOnRules = useAddOnRules(product);
  const additionQuestionRules = useAdditionalQuestionRules();
  const quoteOptionRules = useQuoteSummaryDemandsAndNeedsRules();

  const isAggsQuote = quote.productId !== ProductId.DIRECT;

  const { getError, showValidation, validateOnSubmit } = useValidation(formDetails, {
    ...addOnRules,
    ...additionQuestionRules,
    ...quoteOptionRules,
    ...twentyPercentExcessAgreementRules,
  });
  usePageTracking(meta_title, !!quote.customerInfo);

  const updateQuoteOptionsWithAddOnsUnselected = (
    update: Partial<Omit<QuoteOptions, 'addOnsSelected'>>
  ): void => {
    updateQuoteOptions({
      addOnsSelected: initialAddOnsSelected,
      ...update,
    });

    // Set all twentyPercentExcessConfirmation to undefined in Redux > petsDetails
    petsDetails.forEach((_, i) => {
      petsDetails[i].twentyPercentExcessConfirmation = {
        hasAgreed: undefined,
      };
    });
    updatePetsDetails(petsDetails);

    // Set all twentyPercentExcessConfirmation to undefined in Redux > quote > petInfos
    resetAllTwentyPercentExcessAgreements();
  };

  const catBreedsRefData = useReferenceData('catBreeds')?.catBreeds ?? [];
  const dogBreedsRefData = useReferenceData('dogBreeds')?.dogBreeds ?? [];

  const {
    addOnsForSelectedProduct: addOnDetailsForSelectedProduct,
  } = useAddOnsForSelectedProduct();

  // The useEffect is necessary here, since the state won't have updated the price yet if we track the quote at the point the cover level radio buttons are clicked
  const coverLevel = getCoverLevelFromQuoteOptions(quote.quoteOptions);
  useEffect(() => {
    if (coverLevel) {
      trackCheckoutEvent(ECOMMERCE_STEP, quote, catBreedsRefData, dogBreedsRefData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coverLevel]);

  const borderShadows: BorderShadows = {
    displayOutset: true,
  };

  const vetFeeLimit = getVetFeeLimit();
  const coverPlaceholderData: CoverPercentagePlaceholderData = {
    coveredClaimsPercentage: 0,
    coverLimit: vetFeeLimit ? formatInPoundsWithSign(vetFeeLimit) : '',
  };

  const substitutePlaceholders = replacePlaceholdersRichText(
    coverPercentagePlaceholders,
    coverPlaceholderData
  );

  const vetFeeLimitHelpText =
    accidentAndIllnessCoverDuration === AccidentAndIllnessCoverDuration.Until_Limit
      ? plainTextPlaceholderReplacer(classic_vet_fee_limit_help_text)
      : plainTextPlaceholderReplacer(premier_vet_fee_limit_help_text);
  const vetFeeLimitInfoPanel = (
    <Grid alignLeft>
      <GridItem desktop={6} tabletLandscape={6} tabletPortrait={6}>
        <VetFeeLimitInfoPanel>
          <GridNoWrap>
            <IconWrapper>
              <Icon color={colors.core01} name="info" size="large" />
            </IconWrapper>
            <RichText html={substitutePlaceholders(vetFeeLimitHelpText)} />
          </GridNoWrap>
        </VetFeeLimitInfoPanel>
      </GridItem>
    </Grid>
  );

  const loadingMsgText = (): string => {
    if (isSaveInProgress) return email_spinner_text;
    if (isContinueClicked) return continue_spinner_text;
    return excess_spinner_text;
  };

  return (
    <LoadQuoteWrapper>
      <Layout
        metaTitle={meta_title}
        currentStep={QuoteAndBuyStep.QuoteSummary}
        pageTitle={PageTitle.QuoteSummary}
        sessionExpiryOption={
          isQuoteOptionSelectionValid(quoteOptions)
            ? SessionExpiryOption.EMAIL_QUOTE_SESSION_EXPIRY
            : undefined
        }>
        {(quoteUpdaters?.isLoading || isSaveInProgress) && (
          <LoadingOverlayV2 loadingMessage={loadingMsgText()} timeDuration={10} />
        )}
        <HeroBanner
          data-pii-mask
          heading={substituteQuotePlaceholders(hero.heading)}
          subheading={hero.subheading}
        />
        <Grid alignLeft>
          <GridItem desktop={10} tabletLandscape={10}>
            <form onSubmit={validateOnSubmit(moveNext, scrollAndTrackError)}>
              {isAggsQuote && (
                <AggregatorSummary
                  aggregator={quote.productId}
                  pageTitle={PageTitle.QuoteSummary}
                />
              )}
              <section aria-labelledby={findingTheRightCoverSectionId}>
                <StyledParagraphNoMargin>
                  {finding_the_right_cover_section_heading}
                </StyledParagraphNoMargin>
                <>
                  <QuestionField
                    question={accidentAndIllnessCoverDurationQuestion}
                    maskQuestionData
                    errorText={getError('accidentAndIllnessCoverDuration')}>
                    <RadioInput
                      id={accidentAndIllnessCoverDurationRadioButton}
                      value={accidentAndIllnessCoverDuration}
                      options={[
                        {
                          name: basic_cover_button_text,
                          value: AccidentAndIllnessCoverDuration.Short_Term,
                        },
                        {
                          name: classic_cover_button_text,
                          value: AccidentAndIllnessCoverDuration.Until_Limit,
                        },
                        {
                          name: premier_cover_button_text,
                          value: AccidentAndIllnessCoverDuration.Ongoing,
                        },
                      ]}
                      onChange={(e) => {
                        updateQuoteOptionsWithAddOnsUnselected({
                          accidentAndIllnessCoverDuration: e.target
                            .value as AccidentAndIllnessCoverDuration,
                        });
                        switch (e.target.value) {
                          case AccidentAndIllnessCoverDuration.Short_Term:
                            trackCoverOptionsSelection('Short term');
                            break;
                          case AccidentAndIllnessCoverDuration.Until_Limit:
                            trackCoverOptionsSelection('Limit reached');
                            break;
                          case AccidentAndIllnessCoverDuration.Ongoing:
                            trackCoverOptionsSelection('Ongoing');
                            break;
                          default:
                        }
                      }}
                    />
                  </QuestionField>
                  {accidentAndIllnessCoverDuration ===
                    AccidentAndIllnessCoverDuration.Short_Term && (
                    <>
                      <Grid alignLeft>
                        <GridItem desktop={6} tabletLandscape={6} tabletPortrait={6}>
                          <InfoCardWithMargin
                            id="basicCoverConfirmationBox"
                            {...basicAccidentCoverConfirmation}
                            headerIcon={accidentAndIllnessIcon}
                          />
                        </GridItem>
                      </Grid>
                      <QuestionField
                        question={basicCoverFeeLimitsQuestion}
                        errorText={getError('basicCoverFeeLimit')}>
                        <RadioInput
                          id="basicCoverFeeLimitsRadioButton"
                          orientationOverride={Orientation.horizontal}
                          value={basicCoverFeeLimit?.toString()}
                          options={[
                            {
                              name: '£1,500',
                              value: BasicCoverFeeLimit.Limit_1500.toString(),
                            },
                            {
                              name: '£3,000',
                              value: BasicCoverFeeLimit.Limit_3000.toString(),
                            },
                          ]}
                          onChange={(e) => {
                            updateQuoteOptionsWithAddOnsUnselected({
                              basicCoverFeeLimit: parseInt(
                                e.target.value,
                                10
                              ) as BasicCoverFeeLimit,
                            });
                            trackVetFeeLimitSelection(e.target.value);
                          }}
                        />
                      </QuestionField>
                    </>
                  )}
                  {accidentAndIllnessCoverDuration ===
                    AccidentAndIllnessCoverDuration.Until_Limit && (
                    <>
                      <Grid alignLeft>
                        <GridItem desktop={6} tabletLandscape={6} tabletPortrait={6}>
                          <InfoCardWithMargin
                            id="classicCoverConfirmationBox"
                            {...classicAccidentCoverConfirmation}
                            headerIcon={
                              unwrapSingleton(
                                classic_accident_and_illness_cover_confirmation.icon
                              )?.icon_code
                            }
                          />
                        </GridItem>
                      </Grid>
                      <QuestionFieldReducedBottomMargin
                        data-mask-pii
                        question={classicCoverFeeLimitsQuestion}
                        errorText={getError('classicCoverFeeLimit')}>
                        <RadioInput
                          id="classicCoverFeeLimitsRadioButton"
                          orientationOverride={Orientation.horizontal}
                          value={classicCoverFeeLimit?.toString()}
                          options={[
                            {
                              name: '£4,000',
                              value: ClassicCoverFeeLimit.Limit_4000.toString(),
                            },
                            {
                              name: '£8,000',
                              value: ClassicCoverFeeLimit.Limit_8000.toString(),
                            },
                          ]}
                          onChange={(e) => {
                            updateQuoteOptionsWithAddOnsUnselected({
                              classicCoverFeeLimit: parseInt(
                                e.target.value,
                                10
                              ) as ClassicCoverFeeLimit,
                            });
                            trackVetFeeLimitSelection(e.target.value);
                          }}
                        />
                      </QuestionFieldReducedBottomMargin>
                      {isQuoteOptionSelectionValid(quoteOptions) && product && (
                        <>{vetFeeLimitInfoPanel}</>
                      )}
                    </>
                  )}
                  {accidentAndIllnessCoverDuration ===
                    AccidentAndIllnessCoverDuration.Ongoing && (
                    <>
                      <Grid alignLeft>
                        <GridItem desktop={6} tabletLandscape={6} tabletPortrait={6}>
                          <InfoCardWithMargin
                            id="premierCoverConfirmationBox"
                            {...premierAccidentCoverConfirmation}
                            headerIcon={
                              unwrapSingleton(
                                premier_accident_and_illness_cover_confirmation.icon
                              )?.icon_code
                            }
                          />
                        </GridItem>
                      </Grid>
                      <QuestionFieldReducedBottomMargin
                        question={premierCoverFeeLimitsQuestion}
                        maskQuestionData
                        errorText={getError('premierCoverFeeLimit')}
                        id="premierCoverFeeLimit">
                        <NoLeftRightPaddingRadioInput
                          id={premierCoverFeeLimitsRadioButtonId}
                          orientationOverride={Orientation.horizontal}
                          value={premierCoverFeeLimit?.toString()}
                          wrapItems={false}
                          options={[
                            {
                              name: '£1,000',
                              value: PremierCoverFeeLimit.Limit_1000.toString(),
                            },
                            {
                              name: '£2,000',
                              value: PremierCoverFeeLimit.Limit_2000.toString(),
                            },
                            {
                              name: '£4,000',
                              value: PremierCoverFeeLimit.Limit_4000.toString(),
                            },
                            {
                              name: '£6,000',
                              value: PremierCoverFeeLimit.Limit_6000.toString(),
                            },
                          ]}
                          onChange={(e) => {
                            updateQuoteOptionsWithAddOnsUnselected({
                              premierCoverFeeLimit: parseInt(
                                e.target.value,
                                10
                              ) as PremierCoverFeeLimit,
                            });
                            trackVetFeeLimitSelection(e.target.value);
                          }}
                        />
                      </QuestionFieldReducedBottomMargin>
                      {isQuoteOptionSelectionValid(quoteOptions) && product && (
                        <>{vetFeeLimitInfoPanel}</>
                      )}
                    </>
                  )}
                </>
              </section>
              {isQuoteOptionSelectionValid(quoteOptions) && product && (
                <>
                  <section aria-labelledby={summaryOfCoverSectionId}>
                    <>
                      <SummaryOfCover
                        data-cy="summaryOfCover"
                        isAnnualPayment={isAnnualPayment}
                        paymentOnClick={paymentOnClick}
                        product={product}
                        insuredPetTypes={getPetTypesBeingInsured(quote?.petInfos || [])}
                        classicVetFeeLimit={classicCoverFeeLimit}
                        premierVetFeeLimit={premierCoverFeeLimit}
                        basicVetFeeLimit={basicCoverFeeLimit}
                        treatmentCostOnClick={treatmentCostModalOnClick}
                        productSummaryRef={coverSelectedRef}
                        pageTitle={PageTitle.QuoteSummary}
                      />
                    </>
                    {showAddPetPromoBox && (
                      <StyledGrid alignLeft>
                        <GridItem desktop={10} tabletLandscape={10}>
                          <PromotionalBox
                            pageTitle={PageTitle.QuoteSummary}
                            id="add-another-pet-promotional-box"
                            icon={
                              unwrapSingleton(add_another_pet_promo_box.icon)?.icon_code
                            }
                            heading={add_another_pet_promo_box.heading}
                            information={
                              numberOfPetsInQuote < 3
                                ? add_another_pet_promo_box.less_than_three_pets_information
                                : add_another_pet_promo_box.three_pets_information
                            }
                            button={
                              numberOfPetsInQuote < 3
                                ? {
                                    buttonText:
                                      add_another_pet_promo_box.add_another_pet_button_text,
                                    screenReaderText:
                                      add_another_pet_promo_box.add_another_pet_button_text,
                                    onClick: () => {
                                      toggleAddAnotherPetModal();
                                      trackModalOpen('addAnotherPet');
                                    },
                                  }
                                : undefined
                            }
                          />
                        </GridItem>
                      </StyledGrid>
                    )}
                  </section>
                  {addOnDetailsForSelectedProduct.length > 0 && (
                    <section aria-labelledby={addOnsSectionId} data-cy="addOnsSection">
                      <SectionHeading
                        heading={add_ons_section_heading}
                        id={addOnsSectionId}
                      />
                      <StyledParagraph>{add_ons_section_intro}</StyledParagraph>
                      <AddOnsSection
                        addOns={addOnDetailsForSelectedProduct}
                        pageTitle={PageTitle.QuoteSummary}
                        getError={getError}
                      />
                    </section>
                  )}
                  <section aria-labelledby={excessSectionId} data-cy="excessSection">
                    <SectionHeading
                      heading={
                        product === Product.Premier
                          ? your_excess_section_heading_premier
                          : your_excess_section_heading_non_premier
                      }
                      id={excessSectionId}
                    />

                    <AccidentAndIllnessChooseExcessSection
                      quoteUpdaters={quoteUpdaters}
                      pageTitle={PageTitle.QuoteSummary}
                      updateTwentyPercentExcessAgreement={(
                        update: boolean,
                        index: number
                      ) => {
                        updateTwentyPercentExcessAgreement(update, index);
                        updatePetsDetails(
                          updateItem(petsDetails, index, {
                            twentyPercentExcessConfirmation: {
                              hasAgreed: update,
                            },
                          })
                        );
                        showValidation('twentyPercentExcessAgreements', [
                          'hasAgreed',
                          index,
                        ]);
                      }}
                      getError={getError}
                    />
                  </section>
                  <section aria-labelledby={summaryOfYourNeedsSectionId}>
                    <SectionHeading
                      heading={summary_of_your_needs_section_heading}
                      id={summaryOfYourNeedsSectionId}
                    />
                    <StyledPanel borderShadows={borderShadows}>
                      <SummaryOfYourNeedsTextSection pageTitle={PageTitle.QuoteSummary} />
                      {displayAdditionalQuestion && (
                        <>
                          <DividerWithTopAndBottomMargin />
                          <AdditionalQuestion
                            formValidation={{ getError, showValidation }}
                          />
                        </>
                      )}
                    </StyledPanel>
                    <PolicyDocumentsSection
                      quoteOptions={quoteOptions}
                      pageTitle={PageTitle.QuoteSummary}
                    />
                    <InfoBoxSection />
                    <WhyChooseUsSection pageTitle={PageTitle.QuoteSummary} />
                    <section aria-label="Payment Overview">
                      <PricingOverviewPanel
                        data-cy="pricingOverviewPanel"
                        pageTitle={PageTitle.QuoteSummary}
                        displayQuoteSummaryDiscounts={displayQuoteSummaryDiscounts}
                      />
                    </section>
                  </section>
                  {isAggsQuote && (
                    <AggregatorAgreements pageTitle={PageTitle.QuoteSummary} />
                  )}
                </>
              )}
              <FormFooter
                contentColumns={{ desktop: 10, tabletLandscape: 10 }}
                backButton={{
                  onClick: () => {
                    trackTextButtonClick(PageTitle.QuoteSummary, 'Back');
                    navigate(quoteAndBuyRoutes.aboutYourPolicy);
                  },
                }}
                moveNextButton={{
                  text: next_button_text,
                  onClick: () => {
                    trackTextButtonClick(PageTitle.QuoteSummary, 'Continue');
                    setIsContinueClicked(true);
                  },
                }}
                saveButton={
                  isQuoteOptionSelectionValid(quoteOptions)
                    ? {
                        onClick: saveAndEmailQuote,
                      }
                    : undefined
                }
                printButton={
                  isQuoteOptionSelectionValid(quoteOptions)
                    ? {
                        onClick: () => window.print(),
                      }
                    : undefined
                }
                pageTitle={PageTitle.QuoteSummary}
              />
            </form>
          </GridItem>
        </Grid>
        {savedQuoteConfirmationModal}
        {showTreatmentCostModal && (
          <TreatmentCostModal
            onClose={toggleTreatmentCostModal}
            pageTitle={PageTitle.QuoteSummary}
          />
        )}
        {showAddAnotherPetModal && (
          <AddAnotherPetModal
            data-cy="addAnotherPetModal"
            onClose={toggleAddAnotherPetModal}
            onConfirm={() => {
              updatePetsDetails(addItem(petsDetails, initialPet));
              navigate(
                `${quoteAndBuyRoutes.aboutYourPet}#petsDetails[${numberOfPetsInQuote}]`
              );
              toggleAddAnotherPetModal();
            }}
            pageTitle={PageTitle.QuoteSummary}
          />
        )}
        {totalSelectedPrice && (
          <BannerContainer>
            <PriceBanner
              show={showStickyBanner}
              price={formatInPoundsWithSign(totalSelectedPrice)}
              priceLabel={
                isAnnualPayment ? price_banner.annual_label : price_banner.monthly_label
              }
            />
          </BannerContainer>
        )}
      </Layout>
      {showStickyBanner && <PriceBannerSpace />}
    </LoadQuoteWrapper>
  );
};

export default QuoteSummary;
