import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import PolicyDocuments from 'components/PolicyDocuments';
import { PageTitle } from 'helpers/eventTracking';
import { QuoteOptions } from 'state/formData/quoteOptions';
import { GridWithMargin, StyledHeading, StyledRichText } from './styles';

type PolicyDocumentsContent = {
  csPetPolicyDocuments: {
    heading: string;
    summary: string;
  };
};

const query = graphql`
  query {
    csPetPolicyDocuments {
      heading
      summary
    }
  }
`;

type PolicyDocumentsSectionProps = {
  quoteOptions: QuoteOptions;
  pageTitle: PageTitle;
};

const PolicyDocumentsSection: React.FC<PolicyDocumentsSectionProps> = ({
  quoteOptions,
  pageTitle,
}) => {
  const { heading, summary } = useStaticQuery<PolicyDocumentsContent>(
    query
  ).csPetPolicyDocuments;
  const sectionId = 'policy-documents-section-id';
  return (
    <GridWithMargin as="section" alignLeft aria-labelledby={sectionId}>
      <GridItem>
        <StyledHeading id={sectionId}>{heading}</StyledHeading>
      </GridItem>
      <GridItem>
        <StyledRichText html={summary} pageTitle={pageTitle} />
      </GridItem>
      <GridItem>
        <PolicyDocuments quoteOptions={quoteOptions} />
      </GridItem>
    </GridWithMargin>
  );
};

export default PolicyDocumentsSection;
