import { formatInPoundsWithSign } from 'helpers/poundsHelpers';
import { CsPlaceholders } from './replaceCsPlaceholders';

export type ExcessPlaceholderData = {
  fixedExcess: number;
  percentageExcess: number;
  averageClaimValue: number;
  vetFeeLimit: string;
  compulsaryOrVoluntaryExcessText: string;
};

const calculatePercentageExcessAmount = (
  claimValue: number,
  fixedExcess: number,
  percentageExcess: number
): number => {
  const feeAfterDeductingFixedExcess = claimValue - fixedExcess;
  return (feeAfterDeductingFixedExcess * percentageExcess) / 100;
};

export const excessPlaceholders: CsPlaceholders<ExcessPlaceholderData> = {
  fixedExcessAmount: {
    getSubstitutionText: (excessData) => formatInPoundsWithSign(excessData.fixedExcess),
    isPii: false,
  },
  averageClaimAdditionalExcessAmount: {
    getSubstitutionText: (excessData) =>
      excessData.percentageExcess === 0
        ? '£0'
        : formatInPoundsWithSign(
            calculatePercentageExcessAmount(
              excessData.averageClaimValue,
              excessData.fixedExcess,
              excessData.percentageExcess
            )
          ),
    isPii: false,
  },
  largeClaimAdditionalExcessAmount: {
    getSubstitutionText: (excessData) =>
      excessData.percentageExcess === 0
        ? '£0'
        : formatInPoundsWithSign(
            calculatePercentageExcessAmount(
              parseInt(excessData.vetFeeLimit.replace(/\D+/g, ''), 10),
              excessData.fixedExcess,
              excessData.percentageExcess
            )
          ),
    isPii: false,
  },
  averageClaimValue: {
    getSubstitutionText: (excessData) =>
      formatInPoundsWithSign(excessData.averageClaimValue),
    isPii: false,
  },
  vetFeeLimit: {
    getSubstitutionText: (excessData) => `${excessData.vetFeeLimit}`,
    isPii: false,
  },
  additionalExcessPercentage: {
    getSubstitutionText: (excessData) => `${excessData.percentageExcess}%`,
    isPii: false,
  },
  compulsaryOrVoluntaryExcess: {
    getSubstitutionText: (excessData) => `${excessData.compulsaryOrVoluntaryExcessText}`,
    isPii: false,
  },
  averageClaimAmount: {
    getSubstitutionText: (excessData) =>
      formatInPoundsWithSign(
        excessData.fixedExcess +
          calculatePercentageExcessAmount(
            excessData.averageClaimValue,
            excessData.fixedExcess,
            excessData.percentageExcess
          )
      ),
    isPii: false,
  },
  largeClaimAmount: {
    getSubstitutionText: (excessData) =>
      formatInPoundsWithSign(
        excessData.fixedExcess +
          calculatePercentageExcessAmount(
            parseInt(excessData.vetFeeLimit.replace(/\D+/g, ''), 10),
            excessData.fixedExcess,
            excessData.percentageExcess
          )
      ),
    isPii: false,
  },
};
