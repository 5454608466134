import SegmentedSelector from '@rsa-digital/evo-shared-components/components/Form/SegmentedSelector';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { BorderShadows } from '@rsa-digital/evo-shared-components/components/Panel/types';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { QuoteUpdaters } from 'apiHelpers/quote/useUpdateQuoteCover';
import { isPetAlreadyAgeNineForCover } from 'businessLogic/petAge';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import BooleanRadioInput from 'components/BooleanRadioInput';
import RichTextWithModal from 'components/RichTextWithModal';
import { VoluntaryExcessAmount } from 'helpers/businessConstants';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import useDefaultErrorHandling from 'helpers/errorHandling';
import {
  PageTitle,
  trackExcessSelection,
  trackRadioButtonClick,
} from 'helpers/eventTracking';
import { formatStringToPercentage } from 'helpers/percentageHelpers';
import { petNameReplacer } from 'helpers/placeholders/dynamicPetNameHelpers';
import {
  ExcessPlaceholderData,
  excessPlaceholders,
} from 'helpers/placeholders/excessPlaceholders';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import { replacePlaceholdersRichText } from 'helpers/placeholders/replaceCsPlaceholders';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import useCurrentVetFeeLimit from 'helpers/useCurrentVetFeeLimit';
import useQuestionProcessor from 'helpers/useQuestionProcessor';
import {
  AccidentAndIllnessCoverDuration,
  initialQuoteOptions,
} from 'state/formData/quoteOptions';
import { CsIcon, CsQuestion } from 'types/contentStack';
// import ExcessClaimExampleTable from './ExcessClaimExampleTable';
import {
  ChooseVoluntaryExcessQuestionField,
  DividerWithNarrowMargin,
  DividerWithTopAndBottomMargin,
  ExcessSectionWrapper,
  PetExcessPanel,
  RichTextModalWithModalAndBottomMargin,
  StyledRichText,
  TwentyPercentExcessQuestionField,
  VoluntaryExcessIllustrationClaimWrapper,
  VoluntaryExcessIllustrationContentWrapper,
  VoluntaryExcessIllustrationHeading,
  VoluntaryExcessIllustrationPanel,
} from './styles';
import { TwentyPercentExcessAgreement } from './useTwentyPercentExcessAgreements';
import usePetSpecificText from '../petSpecificText';

type CsChooseExcessSection = {
  csPetQuoteSummaryChooseExcess: {
    pet_name_and_fixed_excess_under_9yrs: string;
    pet_name_and_fixed_excess_9yrs_and_over: string;
    product_specific_text: {
      basic: string;
      classic: string;
      premier: string;
    };
    compulsory_excess: string;
    choose_excess_question: CsQuestion;
    reduce_price_hint: {
      suggest_ten_or_twenty_percent: string;
      suggest_twenty_percent: string;
    };
    twenty_percent_excess: {
      icon: [CsIcon];
      twenty_percent_excess_agreement_heading: string;
      twenty_percent_excess_agreement_question: string;
    };
    example_claim: {
      table: {
        fixed_excess: number;
      };
      supplementary_text: string;
      how_excess_works: string;
    };
    additional_vet_excess: {
      heading: string;
      average_claim_breakdown: string;
      average_claim_total: string;
      average_claim_value: number;
      large_claim_breakdown: string;
      large_claim_total: string;
      compulsory_excess_text: string;
      voluntary_excess_text: string;
    };
  };
};

const query = graphql`
  query {
    csPetQuoteSummaryChooseExcess {
      pet_name_and_fixed_excess_under_9yrs
      pet_name_and_fixed_excess_9yrs_and_over
      product_specific_text {
        basic
        classic
        premier
      }
      compulsory_excess
      choose_excess_question {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
      reduce_price_hint {
        suggest_ten_or_twenty_percent
        suggest_twenty_percent
      }
      twenty_percent_excess {
        icon {
          icon_code
        }
        twenty_percent_excess_agreement_heading
        twenty_percent_excess_agreement_question
      }
      example_claim {
        table {
          fixed_excess
        }
        supplementary_text
        how_excess_works
      }
      additional_vet_excess {
        heading
        average_claim_breakdown
        average_claim_total
        average_claim_value
        large_claim_breakdown
        large_claim_total
        compulsory_excess_text
        voluntary_excess_text
      }
    }
  }
`;

const AccidentAndIllnessChooseExcessSection: React.FC<{
  quoteUpdaters: QuoteUpdaters;
  pageTitle: PageTitle;
  updateTwentyPercentExcessAgreement: (update: boolean, index: number) => void;
  getError: FieldFunction<
    { twentyPercentExcessAgreements: TwentyPercentExcessAgreement[] },
    string | undefined
  >;
}> = ({ quoteUpdaters, pageTitle, updateTwentyPercentExcessAgreement, getError }) => {
  const csContent = useStaticQuery<CsChooseExcessSection>(query)
    .csPetQuoteSummaryChooseExcess;
  const { processQuestion } = useQuestionProcessor(PageTitle.QuoteSummary);
  const excessQuestion = processQuestion(csContent.choose_excess_question);

  const quote = useCurrentQuote();
  const quoteOptions = quote?.quoteOptions ?? initialQuoteOptions;
  const { accidentAndIllnessCoverDuration } = quoteOptions;

  const defaultErrorHandling = useDefaultErrorHandling();

  const getProductSpecificText = (): string => {
    if (accidentAndIllnessCoverDuration === AccidentAndIllnessCoverDuration.Short_Term) {
      return csContent.product_specific_text.basic;
    }
    if (accidentAndIllnessCoverDuration === AccidentAndIllnessCoverDuration.Until_Limit) {
      return csContent.product_specific_text.classic;
    }
    return csContent.product_specific_text.premier;
  };

  const isPetAlreadyAgeNine = useCallback(
    (petDob: string): boolean | null =>
      quote.policyInfo &&
      isPetAlreadyAgeNineForCover(petDob, quote.policyInfo?.coverStartDate),
    [quote]
  );

  const getPetNameAndFixedExcessRichText = (petDob: string, petName: string): string =>
    isPetAlreadyAgeNine(petDob)
      ? petNameReplacer(petName, csContent.pet_name_and_fixed_excess_9yrs_and_over)
      : petNameReplacer(petName, csContent.pet_name_and_fixed_excess_under_9yrs);

  const petSpecificText = usePetSpecificText();

  const getReducePriceHintText = (voluntaryExcess: string): string => {
    const reducePriceHint =
      voluntaryExcess === VoluntaryExcessAmount.Excess_0_Percent
        ? csContent.reduce_price_hint.suggest_ten_or_twenty_percent
        : csContent.reduce_price_hint.suggest_twenty_percent;

    return replacePlaceholdersRichText(quotePlaceholders, quote)(reducePriceHint);
  };

  // We are tracking this internal state even if it's on the redux stored quote so that the state can update instantly for the screenreader users
  const [voluntaryExcessAmounts, setVoluntaryExcessAmounts] = useState<
    VoluntaryExcessAmount[] | undefined
  >();

  useEffect(() => {
    setVoluntaryExcessAmounts(
      quote.petInfos?.map((petInfo) => {
        if (isPetAlreadyAgeNine(petInfo.dob)) {
          return VoluntaryExcessAmount.Excess_20_Percent;
        }
        return petInfo.voluntaryExcessAmount;
      })
    );
  }, [quote, quoteOptions, isPetAlreadyAgeNine]);

  const exampleClaimTable = csContent.example_claim.table;
  const fixedExcess = exampleClaimTable.fixed_excess;

  const vetFeeLimit = useCurrentVetFeeLimit(quote.quoteOptions) || '';

  const getCompulsaryOrVoluntaryExcessText = (
    petDob: string,
    isExample: boolean
  ): string => {
    if (isPetAlreadyAgeNine(petDob) || isExample) {
      return csContent.additional_vet_excess.compulsory_excess_text;
    }
    return csContent.additional_vet_excess.voluntary_excess_text;
  };

  const replaceIndexExcessPlaceholders = (
    index: number,
    petDob: string,
    isExample: boolean
  ): ((csRichTextString: string) => string) => {
    const excessPlaceholderData: ExcessPlaceholderData = {
      fixedExcess,
      percentageExcess: voluntaryExcessAmounts
        ? formatStringToPercentage(
            isExample
              ? VoluntaryExcessAmount.Excess_20_Percent
              : voluntaryExcessAmounts[index]
          )
        : 0,
      averageClaimValue: csContent.additional_vet_excess.average_claim_value,
      vetFeeLimit,
      compulsaryOrVoluntaryExcessText: getCompulsaryOrVoluntaryExcessText(
        petDob,
        isExample
      ),
    };
    return replacePlaceholdersRichText(excessPlaceholders, excessPlaceholderData);
  };

  const additionalQuestionSectionIcon = unwrapSingleton(
    csContent.twenty_percent_excess.icon
  )?.icon_code;

  const panelOutsetBorderShadow: BorderShadows = {
    displayOutset: true,
  };

  return (
    <ExcessSectionWrapper>
      <RichTextModalWithModalAndBottomMargin
        html={getProductSpecificText()}
        pageTitle={pageTitle}
      />
      {quote.petInfos?.map((pet, index) => (
        <>
          <PetExcessPanel borderShadows={panelOutsetBorderShadow} key={pet.petName}>
            <RichTextModalWithModalAndBottomMargin
              html={getPetNameAndFixedExcessRichText(pet.dob, pet.petName)}
              pageTitle={pageTitle}
              data-pii-mask
            />

            <RichTextModalWithModalAndBottomMargin
              html={petSpecificText}
              pageTitle={pageTitle}
            />
            {isPetAlreadyAgeNine(pet.dob) ? (
              <>
                <DividerWithTopAndBottomMargin />
                <RichTextModalWithModalAndBottomMargin
                  html={csContent.compulsory_excess}
                  pageTitle={pageTitle}
                />
              </>
            ) : (
              <ChooseVoluntaryExcessQuestionField
                question={excessQuestion}
                // Index is the unique identifier for pet info
                // eslint-disable-next-line react/no-array-index-key
                key={`petExcessQuestion_[${index}]`}
                maskQuestionData>
                <Grid alignLeft id={`petExcessQuestion_[${index}]`}>
                  <GridItem desktop={3} tabletLandscape={3} tabletPortrait={3}>
                    <SegmentedSelector
                      id={`petExcess[${index}]`}
                      shownOptions={3}
                      value={voluntaryExcessAmounts?.[index]}
                      options={[
                        { name: '0%', value: VoluntaryExcessAmount.Excess_0_Percent },
                        { name: '10%', value: VoluntaryExcessAmount.Excess_10_Percent },
                        {
                          name: '20%',
                          value: VoluntaryExcessAmount.Excess_20_Percent,
                        },
                      ]}
                      onChange={async (e) => {
                        if (!quoteUpdaters) {
                          console.error('No quote loaded - could not update quote');
                          return;
                        }
                        const voluntaryExcessAmountsUpdated = voluntaryExcessAmounts
                          ? [...voluntaryExcessAmounts]
                          : [];
                        voluntaryExcessAmountsUpdated[index] = e.target
                          .value as VoluntaryExcessAmount;

                        setVoluntaryExcessAmounts(voluntaryExcessAmountsUpdated);
                        trackExcessSelection(
                          index,
                          e.target.value as VoluntaryExcessAmount
                        );
                        try {
                          await quoteUpdaters.updatePetExcess(
                            index,
                            e.target.value as VoluntaryExcessAmount
                          );
                        } catch (error) {
                          if (error instanceof Error) {
                            defaultErrorHandling(error);
                          }
                        }
                      }}
                    />
                  </GridItem>
                </Grid>
              </ChooseVoluntaryExcessQuestionField>
            )}
            {!isPetAlreadyAgeNine(pet.dob) &&
              (pet.voluntaryExcessAmount === VoluntaryExcessAmount.Excess_20_Percent ? (
                <TwentyPercentExcessQuestionField
                  question={{
                    questionIcon: additionalQuestionSectionIcon,
                    questionHeading:
                      csContent.twenty_percent_excess
                        .twenty_percent_excess_agreement_heading,
                    questionText:
                      csContent.twenty_percent_excess
                        .twenty_percent_excess_agreement_question,
                  }}
                  errorText={getError('twentyPercentExcessAgreements', [
                    'hasAgreed',
                    index,
                  ])}
                  gridItemProps={{
                    desktop: 8,
                    tabletLandscape: 8,
                    tabletPortrait: 8,
                    mobile: 4,
                  }}>
                  <BooleanRadioInput
                    id={`twentyPercentExcessAgreements[${index}].hasAgreed`}
                    value={
                      quote.petInfos
                        ? quote.petInfos[index]?.twentyPercentExcessConfirmation
                            ?.hasAgreed
                        : undefined
                    }
                    onChange={(value) => {
                      trackRadioButtonClick('Voluntary excess agreement', `${value}`);
                      updateTwentyPercentExcessAgreement(value, index);
                    }}
                  />
                </TwentyPercentExcessQuestionField>
              ) : (
                <RichTextWithModal
                  html={getReducePriceHintText(pet.voluntaryExcessAmount)}
                  pageTitle={pageTitle}
                />
              ))}
            {!isPetAlreadyAgeNine(pet.dob) && <DividerWithTopAndBottomMargin />}
            <VoluntaryExcessIllustrationPanel
              id={`fixedAndAdditionalExcessExampleBox[${index}]`}>
              <VoluntaryExcessIllustrationHeading
                html={replaceIndexExcessPlaceholders(
                  index,
                  pet.dob,
                  false
                )(csContent.additional_vet_excess.heading)}
              />
              <VoluntaryExcessIllustrationContentWrapper>
                <VoluntaryExcessIllustrationClaimWrapper>
                  <StyledRichText
                    html={replaceIndexExcessPlaceholders(
                      index,
                      pet.dob,
                      false
                    )(csContent.additional_vet_excess.average_claim_breakdown)}
                    pageTitle={pageTitle}
                  />
                  <DividerWithNarrowMargin />
                  <StyledRichText
                    html={replaceIndexExcessPlaceholders(
                      index,
                      pet.dob,
                      false
                    )(csContent.additional_vet_excess.average_claim_total)}
                    pageTitle={pageTitle}
                  />
                </VoluntaryExcessIllustrationClaimWrapper>
                <VoluntaryExcessIllustrationClaimWrapper>
                  <StyledRichText
                    html={replaceIndexExcessPlaceholders(
                      index,
                      pet.dob,
                      false
                    )(csContent.additional_vet_excess.large_claim_breakdown)}
                    pageTitle={pageTitle}
                  />
                  <DividerWithNarrowMargin />
                  <StyledRichText
                    html={replaceIndexExcessPlaceholders(
                      index,
                      pet.dob,
                      false
                    )(csContent.additional_vet_excess.large_claim_total)}
                    pageTitle={pageTitle}
                  />
                </VoluntaryExcessIllustrationClaimWrapper>
              </VoluntaryExcessIllustrationContentWrapper>
            </VoluntaryExcessIllustrationPanel>
            {/* <ExcessClaimExampleTable pageTitle={pageTitle} quotePet={pet} /> */}
            <RichTextWithModal
              html={replaceIndexExcessPlaceholders(
                index,
                pet.dob,
                false
              )(csContent.example_claim.supplementary_text)}
              pageTitle={pageTitle}
            />
          </PetExcessPanel>
          {!isPetAlreadyAgeNine(pet.dob) && (
            <PetExcessPanel borderShadows={panelOutsetBorderShadow} key={pet.petName}>
              <RichTextWithModal
                html={replaceIndexExcessPlaceholders(
                  index,
                  pet.dob,
                  true
                )(petNameReplacer(pet.petName, csContent.example_claim.how_excess_works))}
                pageTitle={pageTitle}
              />
              <VoluntaryExcessIllustrationPanel
                id={`exampleFixedAndAdditionalExcessExampleBox[${index}]`}>
                <VoluntaryExcessIllustrationHeading
                  html={replaceIndexExcessPlaceholders(
                    index,
                    pet.dob,
                    true
                  )(csContent.additional_vet_excess.heading)}
                />
                <VoluntaryExcessIllustrationContentWrapper>
                  <VoluntaryExcessIllustrationClaimWrapper>
                    <StyledRichText
                      html={replaceIndexExcessPlaceholders(
                        index,
                        pet.dob,
                        true
                      )(csContent.additional_vet_excess.average_claim_breakdown)}
                      pageTitle={pageTitle}
                    />
                    <DividerWithNarrowMargin />
                    <StyledRichText
                      html={replaceIndexExcessPlaceholders(
                        index,
                        pet.dob,
                        true
                      )(csContent.additional_vet_excess.average_claim_total)}
                      pageTitle={pageTitle}
                    />
                  </VoluntaryExcessIllustrationClaimWrapper>
                  <VoluntaryExcessIllustrationClaimWrapper>
                    <StyledRichText
                      html={replaceIndexExcessPlaceholders(
                        index,
                        pet.dob,
                        true
                      )(csContent.additional_vet_excess.large_claim_breakdown)}
                      pageTitle={pageTitle}
                    />
                    <DividerWithNarrowMargin />
                    <StyledRichText
                      html={replaceIndexExcessPlaceholders(
                        index,
                        pet.dob,
                        true
                      )(csContent.additional_vet_excess.large_claim_total)}
                      pageTitle={pageTitle}
                    />
                  </VoluntaryExcessIllustrationClaimWrapper>
                </VoluntaryExcessIllustrationContentWrapper>
              </VoluntaryExcessIllustrationPanel>
            </PetExcessPanel>
          )}
        </>
      ))}
    </ExcessSectionWrapper>
  );
};

export default AccidentAndIllnessChooseExcessSection;
