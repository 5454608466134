import {
  required,
  validateIf,
} from '@rsa-digital/evo-shared-components/helpers/forms/rules';
import { ValidationRules } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { getAddOnTypes, useAddOnsForSelectedProduct } from 'businessLogic/addOns';
import { graphql, useStaticQuery } from 'gatsby';
import { Product } from 'helpers/productHelpers';
import { AddOnsSelected } from 'state/formData/quoteOptions';

type CsAdditionalQuestionErrorMessages = {
  csPetAddOnsConfig: {
    cover_errored_state: {
      bottom_label_text: string;
    };
  };
};

export const query = graphql`
  query {
    csPetAddOnsConfig {
      cover_errored_state {
        bottom_label_text
      }
    }
  }
`;

export const useAddOnRules = (
  product: Product | null
): ValidationRules<AddOnsSelected> => {
  const {
    csPetAddOnsConfig: {
      cover_errored_state: { bottom_label_text },
    },
  } = useStaticQuery<CsAdditionalQuestionErrorMessages>(query);

  const { getValidProductsForAddOn } = useAddOnsForSelectedProduct();

  return Object.fromEntries(
    getAddOnTypes().map((addOn) => [
      addOn,
      validateIf(() => !!product && getValidProductsForAddOn(addOn).includes(product), [
        required(bottom_label_text),
      ]),
    ])
  );
};
