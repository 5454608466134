import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { lightFontWeight } from '@rsa-digital/evo-shared-components/theme/MoreThan/fonts';
import styled from 'styled-components';

export const StyledGrid = styled.div`
  display: grid;
`;

/* Grid display setting below allows content ordering, meaning Help Text can be shown below 'typical treatment costs' link on mobile */
export const ClaimsCoveredHelpText = styled.div<{
  centerContentsOnMobile: boolean;
}>`
  text-align: ${(p) => (p.centerContentsOnMobile ? 'center' : 'left')};
  margin-left: ${(p) => (p.centerContentsOnMobile ? 0 : spacing(1))};
  display: grid;

  ${mediaQuery.tabletPortrait`
    text-align: left;
    margin-left: ${spacing(1)}
  `}

  ${mediaQuery.tabletLandscape`
    margin-left: ${spacing(2)};
  `}

  button {
    width: 100%;
    ${mediaQuery.tabletPortrait`
      text-align: left;
    `}
  }
`;

export const HelpText = styled.p`
  order: 1;
  margin: ${spacing(2)} 0 ${spacing(1)};
  ${fonts.paragraph};

  && {
    font-size: 1rem; // 16px
    font-weight: 300;

    ${mediaQuery.tabletLandscape`
      font-size: 0.875rem; // 14px
    `}
  }

  // width 100% is needed so the text wraps in IE
  width: 100%;

  ${mediaQuery.tabletPortrait`
    order: 0;
    margin: 0;
    margin-top: ${spacing(3)};
  `}
`;

export const ParagraphLightWithMarginTop = styled.p`
  ${fonts.paragraph};
  & {
    font-weight: ${lightFontWeight};
    max-width: 500px;
  }
  margin: ${spacing(2)} ${spacing(2)} 0;
`;
