import Icon from '@rsa-digital/evo-shared-components/components/Icon';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import React from 'react';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import { replacePlaceholdersPlainText } from 'helpers/placeholders/replaceCsPlaceholders';
import { Product } from 'helpers/productHelpers';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { CsModal } from 'types/contentStack';
import {
  FeatureContainer,
  IconContainer,
  InlineDiv,
  ParagraphSpanLight,
  StyledButtonLink,
} from './styles';

type ProductFeatureProps = {
  includedWithProducts: Product[];
  modal: [] | [CsModal];
  featureText: string;
  product: Product;
  setModalId: React.Dispatch<React.SetStateAction<string | null>>;
};

const ProductFeature: React.FC<ProductFeatureProps> = ({
  includedWithProducts,
  modal,
  product,
  featureText,
  setModalId,
}) => {
  const optionalModal = unwrapSingleton(modal);
  // text between two pipe symbols is used as a modal button
  const featureTexts = featureText.split('|');
  const quote = useCurrentQuote();
  const plainTextPlaceholderReplacer = replacePlaceholdersPlainText(
    quotePlaceholders,
    quote
  );

  return (
    <FeatureContainer>
      <IconContainer>
        {includedWithProducts.includes(product) ? (
          <Icon name="tick" aria-label="Included" size="small" color={colors.core01} />
        ) : (
          <Icon
            name="cross"
            aria-label="Not included"
            size="small"
            color={colors.notificationError}
          />
        )}
      </IconContainer>
      {featureTexts.length === 1 && (
        <ParagraphSpanLight>
          {plainTextPlaceholderReplacer(featureTexts[0])}
        </ParagraphSpanLight>
      )}
      {featureTexts.length > 1 && (
        <span>
          <ParagraphSpanLight>
            {plainTextPlaceholderReplacer(featureTexts[0])}
          </ParagraphSpanLight>
          <InlineDiv>
            {featureTexts[1] &&
            optionalModal &&
            includedWithProducts.includes(product) ? (
              <StyledButtonLink
                onClick={() => {
                  setModalId(optionalModal.modal_id);
                }}>
                {plainTextPlaceholderReplacer(featureTexts[1])}
              </StyledButtonLink>
            ) : (
              <ParagraphSpanLight>
                {plainTextPlaceholderReplacer(featureTexts[1])}
              </ParagraphSpanLight>
            )}
          </InlineDiv>
          <ParagraphSpanLight>
            {plainTextPlaceholderReplacer(featureTexts[2])}
          </ParagraphSpanLight>
        </span>
      )}
    </FeatureContainer>
  );
};

export default ProductFeature;
