import { Grid } from '@rsa-digital/evo-shared-components/components/Grid';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import RichTextWithModal from 'components/RichTextWithModal';
import { avoidBreakOnPrint } from 'helpers/cssPrintHelpers';

export const StyledHeading = styled.h3`
  ${fonts.headingMedium}

  margin-top: 0;
  margin-bottom: ${spacing(2)};

  ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(3)};
  `}
`;

export const StyledRichText = styled(RichTextWithModal)`
  ${RichText} {
    p {
      & {
        ${fonts.paragraphLarge};
        font-weight: 300;
      }
    }
  }

  margin-bottom: ${spacing(1)};
`;

export const GridWithMargin = styled(Grid)`
  && {
    margin-bottom: ${spacing(7)};
  }

  ${avoidBreakOnPrint}
`;
