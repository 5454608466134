import { CoverPercentagePlaceholderData } from './coverPercentagePlaceholders';
import { CsPlaceholders } from './replaceCsPlaceholders';

export type classicPremierCoverPercentagePlaceholderData = CoverPercentagePlaceholderData & {
  petType: string;
};

export const classicPremierCoverPercentagePlaceholders: CsPlaceholders<classicPremierCoverPercentagePlaceholderData> = {
  coverLimit: {
    getSubstitutionText: (placeholders) => placeholders.coverLimit,
    isPii: false,
  },
  coveredClaimsPercentage: {
    getSubstitutionText: (placeholders) => `${placeholders.coveredClaimsPercentage}%`,
    isPii: false,
  },
  notCoveredClaimsPercentage: {
    getSubstitutionText: (placeholders) =>
      `${100 - placeholders.coveredClaimsPercentage}`,
    isPii: false,
  },
  petType: {
    getSubstitutionText: (placeholders) => placeholders.petType,
    isPii: false,
  },
};
