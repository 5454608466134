import { QuoteResponsePetInfo } from 'apiHelpers/quote/quoteResponse';
import { PetType } from 'components/QuoteSummary/ProductFeaturesTable';
import { petType_CAT, petType_DOG } from './referenceDataConstants';

export const getPetTypesBeingInsured = (petInfos: QuoteResponsePetInfo[]): PetType => {
  const dogs = petInfos.filter((petInfo) => petInfo.petType === petType_DOG);
  const cats = petInfos.filter((petInfo) => petInfo.petType === petType_CAT);

  if (dogs.length > 0) {
    return cats.length > 0 ? PetType.CatsAndDogs : PetType.DogsOnly;
  }
  return PetType.CatsOnly;
};
