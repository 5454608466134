import React from 'react';
import { PageTitle, trackRichTextLinkClick } from 'helpers/eventTracking';
import { ColouredRichText, TitleBannerConainer, TitleText } from './styles';

type TitleBannerProps = {
  heading: string;
  subheading?: string;
  pageTitle: PageTitle;
};

const TitleBanner: React.FC<TitleBannerProps> = ({ heading, subheading, pageTitle }) => (
  <TitleBannerConainer>
    <TitleText>{heading}</TitleText>
    {subheading && (
      <ColouredRichText
        html={subheading}
        onLinkClick={trackRichTextLinkClick(pageTitle)}
      />
    )}
  </TitleBannerConainer>
);

export default TitleBanner;
