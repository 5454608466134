import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import { SummaryOfCoverSection } from '../styles';

export const ChecklistSectionHeading = styled.h3<{
  centerContentsOnMobile: boolean;
}>`
  ${fonts.headingSmall};
  text-align: ${(p) => (p.centerContentsOnMobile ? 'center' : 'left')};
  margin: 0 0 ${spacing(3)} ${spacing(1)};

  ${mediaQuery.tabletPortrait`
    text-align: left;
  `}
  ${mediaQuery.desktop`
    margin-left: ${spacing(2)};
  `}
`;

export const ProductFeatureSectionContainer = styled(SummaryOfCoverSection)`
  border-bottom: 1px solid ${colors.neutral04};
  border-radius: 0 0 ${spacing(1)} ${spacing(1)};
  margin-bottom: ${spacing(2)};
  padding-bottom: ${spacing(4)};
`;

export const DivWithLeftMarginTablet = styled.div`
  ${mediaQuery.tabletLandscape`
    margin-left: ${spacing(0.5)};
  `}
  ${mediaQuery.desktop`
    margin-left: ${spacing(0)};
  `}
`;
