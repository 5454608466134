import { graphql, useStaticQuery } from 'gatsby';
import { petType_CAT, petType_DOG } from 'helpers/referenceDataConstants';
import { useCurrentQuote } from 'helpers/useCurrentQuote';

type CsExcessSection = {
  csPetQuoteSummaryChooseExcess: {
    pet_specific_text: {
      dog_only: string;
      cat_only: string;
      dog_and_cat: string;
    };
  };
};

const query = graphql`
  query {
    csPetQuoteSummaryChooseExcess {
      pet_specific_text {
        dog_only
        cat_only
        dog_and_cat
      }
    }
  }
`;

const usePetSpecificText = (): string => {
  const { pet_specific_text } = useStaticQuery<CsExcessSection>(
    query
  ).csPetQuoteSummaryChooseExcess;

  const quote = useCurrentQuote();

  const quoteHasDog = quote.petInfos?.some((pet) => pet.petType === petType_DOG);
  const quoteHasCat = quote.petInfos?.some((pet) => pet.petType === petType_CAT);

  if (quoteHasCat) {
    if (quoteHasDog) {
      return pet_specific_text.dog_and_cat;
    }
    return pet_specific_text.cat_only;
  }
  return pet_specific_text.dog_only;
};

export default usePetSpecificText;
