import { graphql, useStaticQuery } from 'gatsby';
import { PetType } from 'components/QuoteSummary/ProductFeaturesTable';
import {
  BasicCoverFeeLimit,
  ClassicCoverFeeLimit,
  PremierCoverFeeLimit,
} from 'state/formData/quoteOptions';
import { warningWithDetail } from './errorReporting';
import { Product } from './productHelpers';

type CsPercentageOfClaimsCovered = {
  csPetQuoteSummaryPercentageOfClaimsCoveredBanner: {
    cover_percentages_cats_and_dogs: {
      basic_1500: number;
      basic_3000: number;
      classic_4000: number;
      classic_8000: number;
      premier_1000: number;
      premier_2000: number;
      premier_4000: number;
      premier_6000: number;
    };
    cover_percentages_dogs_only: {
      basic_1500: number;
      basic_3000: number;
      classic_4000: number;
      classic_8000: number;
      premier_1000: number;
      premier_2000: number;
      premier_4000: number;
      premier_6000: number;
    };
    cover_percentages_cats_only: {
      basic_1500: number;
      basic_3000: number;
      classic_4000: number;
      classic_8000: number;
      premier_1000: number;
      premier_2000: number;
      premier_4000: number;
      premier_6000: number;
    };
  };
};

const query = graphql`
  query {
    csPetQuoteSummaryPercentageOfClaimsCoveredBanner {
      cover_percentages_cats_and_dogs {
        basic_1500
        basic_3000
        classic_4000
        classic_8000
        premier_1000
        premier_2000
        premier_4000
        premier_6000
      }
      cover_percentages_dogs_only {
        basic_1500
        basic_3000
        classic_4000
        classic_8000
        premier_1000
        premier_2000
        premier_4000
        premier_6000
      }
      cover_percentages_cats_only {
        basic_1500
        basic_3000
        classic_4000
        classic_8000
        premier_1000
        premier_2000
        premier_4000
        premier_6000
      }
    }
  }
`;

type coverPercentagesList = {
  basic_1500: number;
  basic_3000: number;
  classic_4000: number;
  classic_8000: number;
  premier_1000: number;
  premier_2000: number;
  premier_4000: number;
  premier_6000: number;
};

const usePercentageOfClaimsCovered = (
  product: Product,
  feeLimit: number,
  insuredPetTypes: PetType
): number => {
  const {
    cover_percentages_cats_and_dogs,
    cover_percentages_dogs_only,
    cover_percentages_cats_only,
  } = useStaticQuery<CsPercentageOfClaimsCovered>(
    query
  ).csPetQuoteSummaryPercentageOfClaimsCoveredBanner;

  const getCoverPercentagesListForInsuredPetTypes = (): coverPercentagesList => {
    switch (insuredPetTypes) {
      case PetType.CatsOnly:
        return cover_percentages_cats_only;
      case PetType.DogsOnly:
        return cover_percentages_dogs_only;
      default:
        return cover_percentages_cats_and_dogs;
    }
  };

  const coverPercentagesList = getCoverPercentagesListForInsuredPetTypes();

  if (product === Product.Basic) {
    switch (feeLimit) {
      case BasicCoverFeeLimit.Limit_1500:
        return coverPercentagesList.basic_1500;
      case BasicCoverFeeLimit.Limit_3000:
        return coverPercentagesList.basic_3000;
      default:
        warningWithDetail(
          'Could not find percentage of claims covered for that vet fee limit',
          'this could be caused by updating percentages in CS but not in the enum'
        );
        return 0;
    }
  }

  if (product === Product.Classic) {
    switch (feeLimit) {
      case ClassicCoverFeeLimit.Limit_4000:
        return coverPercentagesList.classic_4000;
      case ClassicCoverFeeLimit.Limit_8000:
        return coverPercentagesList.classic_8000;
      default:
        warningWithDetail(
          'Could not find percentage of claims covered for that vet fee limit',
          'this could be caused by updating percentages in CS but not in the enum'
        );
        return 0;
    }
  }

  /* Covering each of these cases is not valuable */
  /* istanbul ignore next */
  if (product === Product.Premier) {
    switch (feeLimit) {
      case PremierCoverFeeLimit.Limit_1000:
        return coverPercentagesList.premier_1000;
      case PremierCoverFeeLimit.Limit_2000:
        return coverPercentagesList.premier_2000;
      case PremierCoverFeeLimit.Limit_4000:
        return coverPercentagesList.premier_4000;
      case PremierCoverFeeLimit.Limit_6000:
        return coverPercentagesList.premier_6000;
      default:
        warningWithDetail(
          'Could not find percentage of claims covered for that vet fee limit',
          'this could be caused by updating percentages in CS but not in the enum'
        );
        return 0;
    }
  }

  warningWithDetail(
    'Could not find percentage of claims covered for that product type',
    'currently, this hook should only be used for classic / premier cover'
  );
  return 0;
};

export default usePercentageOfClaimsCovered;
