import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { graphql, useStaticQuery } from 'gatsby';
import React, { MouseEventHandler } from 'react';
import { PetType } from 'components/QuoteSummary/ProductFeaturesTable';
import CoverSummaryPricePanel from 'components/QuoteSummary/SummaryOfCover/CoverSummaryPricePanel';
import {
  CoverPercentagePlaceholderData,
  coverPercentagePlaceholders,
} from 'helpers/placeholders/coverPercentagePlaceholders';
import {
  replacePlaceholdersPlainText,
  replacePlaceholdersRichText,
} from 'helpers/placeholders/replaceCsPlaceholders';
import { Product } from 'helpers/productHelpers';
import usePercentageOfClaimsCovered from 'helpers/usePercentageOfClaimsCovered';
import { BasicCoverFeeLimit } from 'state/formData/quoteOptions';
import { ParagraphLightWithMarginTop } from './styles';
import { GridWithTopMargin } from '../ClassicPremierProductSummarySection/styles';
import DonutChart from '../DonutChart';
import { SummaryOfCoverSection } from '../styles';

type AccidentBasicProductSummarySectionProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  paymentTypeText: string;
  isAnnualPayment: boolean;
  paymentOnClick: MouseEventHandler;
  product: Product;
  vetFeeLimit: BasicCoverFeeLimit | undefined;
  insuredPetTypes: PetType;
};

type AccidentBasicProductSummarySectionCsProps = {
  csPetQuoteSummaryPercentageOfClaimsCoveredBanner: {
    basic_donut_chart_text: string;
    basic_subheading: string;
  };
};

export const query = graphql`
  query {
    csPetQuoteSummaryPercentageOfClaimsCoveredBanner {
      basic_donut_chart_text
      basic_subheading
    }
  }
`;

const AccidentBasicProductSummarySection: React.FC<AccidentBasicProductSummarySectionProps> = ({
  isAnnualPayment,
  paymentOnClick,
  vetFeeLimit,
  insuredPetTypes,
  product,
}) => {
  const {
    csPetQuoteSummaryPercentageOfClaimsCoveredBanner: {
      basic_donut_chart_text,
      basic_subheading,
    },
  } = useStaticQuery<AccidentBasicProductSummarySectionCsProps>(query);

  const coverPercentage = usePercentageOfClaimsCovered(
    product,
    vetFeeLimit || 0,
    insuredPetTypes
  );

  const coverPlaceholderData: CoverPercentagePlaceholderData = {
    coveredClaimsPercentage: coverPercentage,
    coverLimit: '',
  };

  const substituteRichTextPlaceholders = replacePlaceholdersRichText(
    coverPercentagePlaceholders,
    coverPlaceholderData
  );

  const substitutePlaceholders = replacePlaceholdersPlainText(
    coverPercentagePlaceholders,
    coverPlaceholderData
  );

  return (
    <SummaryOfCoverSection>
      <GridWithTopMargin alignLeft>
        <GridItem desktop={10} tabletLandscape={10} tabletPortrait={8}>
          <Grid alignLeft>
            <GridItem desktop={6} tabletLandscape={6} tabletPortrait={5}>
              <CoverSummaryPricePanel
                isAnnualPayment={isAnnualPayment}
                paymentOnClick={paymentOnClick}
              />
            </GridItem>
            {product === Product.Basic && (
              <GridItem desktop={4} tabletLandscape={4} tabletPortrait={3}>
                <DonutChart
                  coveredClaimsPercentage={coverPercentage}
                  chartText={substituteRichTextPlaceholders(basic_donut_chart_text)}
                  product={product}
                />
              </GridItem>
            )}
          </Grid>
        </GridItem>
      </GridWithTopMargin>
      {product === Product.Basic && (
        <ParagraphLightWithMarginTop>
          {substitutePlaceholders(basic_subheading)}
        </ParagraphLightWithMarginTop>
      )}
    </SummaryOfCoverSection>
  );
};

export default AccidentBasicProductSummarySection;
