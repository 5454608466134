import InfoBanner from '@rsa-digital/evo-shared-components/components/InfoBanner';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import RichTextWithModal from 'components/RichTextWithModal';

export const Heading = styled.h3`
  ${fonts.headingSmall}

  margin-top: 0;
`;

export const StyledRichTextWithModal = styled(RichTextWithModal)`
  && {
    p,
    li {
      ${fonts.paragraphLarge}
    }
    span.bullet {
      vertical-align: initial;
    }
  }
`;

export const InfoBannerWithTopMargin = styled(InfoBanner)`
  margin-top: ${spacing(4)};
`;
