import { TooltipProps } from '@rsa-digital/evo-shared-components/components/Form/Field/FieldInfo/Tooltip';
import { InputReactElement } from '@rsa-digital/evo-shared-components/components/Form/inputTypes';
import { GridItemProps } from '@rsa-digital/evo-shared-components/components/Grid';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import React from 'react';
import { Question } from 'types/forms';
import { AnimatedFieldWithOptionalIconAndHeading } from './styles';

type QuestionFieldProps = {
  question: Question;
  errorText?: string;
  warningText?: string;
  children: InputReactElement;
  id?: string;
  initiallyShowTooltip?: boolean;
  gridItemProps?: GridItemProps;
  maskQuestionData?: boolean;
} & ComponentProps;

/* This component should only be used when a QuestionField requires a header and/or an icon.
In all other cases, use the existing QuestionField / Field types from the Component Library. */
const QuestionFieldWithOptionalIconAndHeading: React.FC<QuestionFieldProps> = ({
  question,
  errorText,
  warningText,
  children,
  id,
  initiallyShowTooltip,
  gridItemProps,
  maskQuestionData,
  ...props
}) => {
  const labelComponentProps = {
    'data-pii-mask': maskQuestionData,
  } as ComponentProps;

  return (
    <AnimatedFieldWithOptionalIconAndHeading
      {...componentProps(props)}
      id={id}
      icon={question.questionIcon}
      heading={question.questionHeading}
      introductoryText={question.introductoryText}
      label={question.questionText}
      explanatoryText={question.explanatoryText}
      tooltip={
        question.tooltip
          ? ({ ...question.tooltip, initiallyShowTooltip } as TooltipProps)
          : undefined
      }
      alertBody={question.alertText}
      errorText={errorText}
      warningText={warningText}
      fieldGridItemProps={gridItemProps}
      labelComponentProps={labelComponentProps}
      alignLeft>
      {children}
    </AnimatedFieldWithOptionalIconAndHeading>
  );
};

export default QuestionFieldWithOptionalIconAndHeading;
