import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import { appliesToSelection } from 'helpers/productHelpers';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { CsApplicableProducts, CsInfoBox } from 'types/contentStack';
import { StyledInfoBox } from './styles';

export type CsPetQuoteSummaryOfYourNeeds = {
  csPetQuoteSummaryOfYourNeeds: {
    info_sections: {
      applicable_products: CsApplicableProducts;
      info_box: CsInfoBox;
    }[];
  };
};

const query = graphql`
  query {
    csPetQuoteSummaryOfYourNeeds {
      info_sections {
        applicable_products {
          product_selector
        }
        info_box {
          callout_icon {
            icon_code
          }
          callout_heading
          callout_body
          body
        }
      }
    }
  }
`;

const InfoBoxSection: React.FC<ComponentProps> = ({ ...rest }) => {
  const quote = useCurrentQuote();

  const {
    csPetQuoteSummaryOfYourNeeds: { info_sections },
  } = useStaticQuery<CsPetQuoteSummaryOfYourNeeds>(query);

  const infoSections = info_sections.filter((section) =>
    appliesToSelection(quote.quoteOptions, section.applicable_products)
  );

  return (
    <section>
      <Grid alignLeft>
        {infoSections.map((infoSection) => (
          <GridItem desktop={10} tabletLandscape={10} key={infoSection.info_box.body}>
            <StyledInfoBox
              {...componentProps(rest)}
              mainPart={infoSection.info_box.body}
              mainContainerGridItemProps={{ desktop: 6, tabletLandscape: 6 }}
              calloutHeading={infoSection.info_box.callout_heading}
              calloutBody={infoSection.info_box.callout_body}
              calloutIcon={unwrapSingleton(infoSection.info_box.callout_icon)?.icon_code}
              calloutContainerGridItemProps={{ desktop: 4, tabletLandscape: 4 }}
              data-cy="infoBox"
            />
          </GridItem>
        ))}
      </Grid>
    </section>
  );
};

export default InfoBoxSection;
