import WhyChooseUs, {
  ReasonToChoose,
} from '@rsa-digital/evo-shared-components/components/WhyChooseUs';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import {
  ActiveProductId,
  activeProductMatchesChosenProducts,
} from 'helpers/activeProductHelpers';
import { processImageAsset } from 'helpers/csTypeProcessors';
import { PageTitle, trackRichTextLinkClick } from 'helpers/eventTracking';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { CsAsset } from 'types/contentStack';

type WhyChooseUsPages = 'Quote Summary' | 'Quote Generating';

type CsReasonToChoose = {
  additional_info: string;
  explanation: string;
  reason_heading: string;
  image: CsAsset | null;
  display_on_pages: WhyChooseUsPages[];
  active_routes_to_quote: ActiveProductId[];
};

type CsWhyChooseUs = {
  csPetWhyChooseUs: {
    why_choose_us: {
      heading: string;
      reason: CsReasonToChoose[];
    };
  };
};

const query = graphql`
  query {
    csPetWhyChooseUs {
      why_choose_us {
        heading
        reason {
          additional_info
          explanation
          reason_heading
          image {
            ...CsAsset
          }
          display_on_pages
          active_routes_to_quote
        }
      }
    }
  }
`;

const WhyChooseUsSection: React.FC<{
  pageTitle: PageTitle;
}> = ({ pageTitle }) => {
  const {
    csPetWhyChooseUs: {
      why_choose_us: { heading, reason: reasons },
    },
  } = useStaticQuery<CsWhyChooseUs>(query);

  const currentPage =
    pageTitle === PageTitle.QuoteSummary ? 'Quote Summary' : 'Quote Generating';

  const quote = useCurrentQuote();

  const processedReasons: ReasonToChoose[] = reasons
    .filter(
      (reason) =>
        reason.display_on_pages.includes(currentPage) &&
        (activeProductMatchesChosenProducts(
          quote.productId,
          reason.active_routes_to_quote
        ) ||
          currentPage === 'Quote Generating')
    )
    .map((reason) => ({
      ...reason,
      image: processImageAsset(reason.image),
    }));

  return (
    <WhyChooseUs
      title={heading}
      reasonsToChoose={processedReasons}
      onLinkClick={trackRichTextLinkClick(pageTitle)}
      data-cy="why_choose_us"
    />
  );
};

export default WhyChooseUsSection;
