import DocumentDownloadLink from '@rsa-digital/evo-shared-components/components/DocumentDownloadLink';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import styled from 'styled-components';

export const StyledDocumentDownloadLink = styled(DocumentDownloadLink)`
  &&& {
    color: ${colors.core01};
    text-decoration: underline;

    &:visited {
      color: ${colors.core01};
    }

    &:hover {
      text-decoration: none;
    }
  }
`;
