import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import { graphql, useStaticQuery } from 'gatsby';
import React, { MouseEventHandler } from 'react';
import { PetType } from 'components/QuoteSummary/ProductFeaturesTable';
import {
  classicPremierCoverPercentagePlaceholderData,
  classicPremierCoverPercentagePlaceholders,
} from 'helpers/placeholders/classicPremierCoverPercentagePlaceholders';
import { replacePlaceholdersPlainText } from 'helpers/placeholders/replaceCsPlaceholders';
import { formatInPoundsWithSign } from 'helpers/poundsHelpers';
import { Product } from 'helpers/productHelpers';
import usePercentageOfClaimsCovered from 'helpers/usePercentageOfClaimsCovered';
import { ClassicCoverFeeLimit, PremierCoverFeeLimit } from 'state/formData/quoteOptions';
import { ClaimsCoveredHelpText, GridWithTopMargin, HelpText } from './styles';
import CoverSummaryPricePanel from '../CoverSummaryPricePanel';
import DonutChart from '../DonutChart';
import { StyledButtonLink, SummaryOfCoverSection } from '../styles';

type ClassicPremierProductSummarySectionProps = {
  isAnnualPayment: boolean;
  product: Product;
  vetFeeLimit: ClassicCoverFeeLimit | PremierCoverFeeLimit | undefined;
  paymentOnClick: MouseEventHandler;
  treatmentCostOnClick: MouseEventHandler;
  insuredPetTypes: PetType;
};

type ClassicPremierProductSummarySectionCsProps = {
  csPetQuoteSummaryPercentageOfClaimsCoveredBanner: {
    donut_chart_text: string;
    subheading: string;
    typical_treatment_cost_link: string;
    claims_caveat_text: {
      dog_text: string;
      cat_text: string;
    };
  };
};

export const query = graphql`
  query {
    csPetQuoteSummaryPercentageOfClaimsCoveredBanner {
      donut_chart_text
      subheading
      typical_treatment_cost_link
      claims_caveat_text {
        dog_text
        cat_text
      }
    }
  }
`;

const ClassicPremierProductSummarySection: React.FC<ClassicPremierProductSummarySectionProps> = ({
  isAnnualPayment,
  vetFeeLimit,
  product,
  paymentOnClick,
  treatmentCostOnClick,
  insuredPetTypes,
}) => {
  const {
    csPetQuoteSummaryPercentageOfClaimsCoveredBanner: {
      subheading,
      typical_treatment_cost_link,
      donut_chart_text,
      claims_caveat_text,
    },
  } = useStaticQuery<ClassicPremierProductSummarySectionCsProps>(query);

  const coverPercentage = usePercentageOfClaimsCovered(
    product,
    vetFeeLimit || 0,
    insuredPetTypes
  );

  const getPetText = (): string => {
    switch (insuredPetTypes) {
      case PetType.CatsOnly:
        return claims_caveat_text.cat_text;
      case PetType.DogsOnly:
        return claims_caveat_text.dog_text;
      default:
        return '';
    }
  };

  const coverPlaceholderData: classicPremierCoverPercentagePlaceholderData = {
    coveredClaimsPercentage: coverPercentage,
    coverLimit: vetFeeLimit ? formatInPoundsWithSign(vetFeeLimit) : '',
    petType: getPetText(),
  };
  const substitutePlaceholders = replacePlaceholdersPlainText(
    classicPremierCoverPercentagePlaceholders,
    coverPlaceholderData
  );

  /* the CoveredCLaimsPercentage and CoverLimit placeholders need to be core01, while the rest of the text is neutral.
  We do that by adding a span which we style in the donut text styling */
  const wrapDonutChartPlaceholdersWithSpan = donut_chart_text
    .replace('{{coveredClaimsPercentage}}*', '<span>{{coveredClaimsPercentage}}*</span>')
    .replace('{{coverLimit}}', '<span>{{coverLimit}}</span>');

  return (
    <SummaryOfCoverSection>
      <GridWithTopMargin data-cy="percentageOfClaimsCoveredSection">
        <GridItem desktop={10} tabletLandscape={10} tabletPortrait={8}>
          <Grid alignLeft>
            <GridItem desktop={6} tabletLandscape={6} tabletPortrait={5}>
              <CoverSummaryPricePanel
                isAnnualPayment={isAnnualPayment}
                paymentOnClick={paymentOnClick}
                centerContentsOnMobile
              />
            </GridItem>
            <GridItem desktop={4} tabletLandscape={4} tabletPortrait={3}>
              <DonutChart
                coveredClaimsPercentage={coverPercentage}
                chartText={substitutePlaceholders(wrapDonutChartPlaceholdersWithSpan)}
                product={product}
              />
            </GridItem>
          </Grid>
        </GridItem>
      </GridWithTopMargin>
      <ClaimsCoveredHelpText centerContentsOnMobile>
        <HelpText>{substitutePlaceholders(subheading)}</HelpText>
        <StyledButtonLink onClick={treatmentCostOnClick} color={colors.core01}>
          {typical_treatment_cost_link}
        </StyledButtonLink>
      </ClaimsCoveredHelpText>
    </SummaryOfCoverSection>
  );
};

export default ClassicPremierProductSummarySection;
