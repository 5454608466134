import ErrorContainer from '@rsa-digital/evo-shared-components/components/Form/Field/ErrorContainer';
import FieldGrid from '@rsa-digital/evo-shared-components/components/Form/Field/FieldGrid';
import FieldInfo, {
  ExplanatoryText,
  Label,
  Legend,
  Tooltip,
  TooltipProps,
} from '@rsa-digital/evo-shared-components/components/Form/Field/FieldInfo';
import {
  defaultContainerId,
  defaultErrorId,
  defaultExplanatoryTextId,
  defaultLabelId,
  defaultTooltipId,
} from '@rsa-digital/evo-shared-components/components/Form/idHelpers';
import { InputReactElement } from '@rsa-digital/evo-shared-components/components/Form/inputTypes';
import { GridItemProps } from '@rsa-digital/evo-shared-components/components/Grid';
import Icon from '@rsa-digital/evo-shared-components/components/Icon';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import { applyComponentTheme } from '@rsa-digital/evo-shared-components/theme';
import React from 'react';
import {
  GridItemFlex,
  Heading,
  IconWrapper,
  LeftAlignTextOnMobile,
  RichTextWithMargins,
} from './styles';

export type FieldProps = {
  children: InputReactElement;
  errorText?: string;
  warningText?: string;
  icon?: string;
  heading?: string;
  introductoryText?: string;
  label: string;
  tooltip?: TooltipProps;
  alertBody?: string;
  explanatoryText?: string;
  fieldGridItemProps?: GridItemProps;
  alignLeft?: boolean;
  labelComponentProps?: ComponentProps;
} & ComponentProps;

/* This component should only be used when a QuestionField requires a header and/or an icon.
This has been added specifically for MoreThan due to the designs in EP-3480, EP-3487 and EP-3488.
In all other cases, use the existing QuestionField / Field types from the Component Library. */
const FieldWithOptionalIconAndHeading: React.FC<FieldProps> = ({
  children,
  errorText,
  warningText,
  icon,
  heading,
  introductoryText,
  label,
  tooltip,
  alertBody,
  explanatoryText,
  fieldGridItemProps,
  alignLeft,
  labelComponentProps,
  ...rest
}) => {
  /* Some fields, e.g. Radios and Dates have multiple inputs within them. In these cases we should
  render as a fieldset rather than a label to group those inputs together. */
  const useFieldSet = children.type.isFieldSet;

  const childId = children.props.id;
  const errorId = childId && defaultErrorId(childId);
  const containerId = childId && defaultContainerId(childId);
  const labelId = childId && defaultLabelId(childId);
  const tooltipId = childId && defaultTooltipId(childId);
  const explanatoryTextId = childId && defaultExplanatoryTextId(childId);

  const labelProps = labelComponentProps && componentProps(labelComponentProps);

  return (
    <ErrorContainer
      errorText={errorText}
      warningText={warningText}
      {...componentProps(rest)}
      fieldSet={useFieldSet}
      id={containerId}
      errorId={errorId}>
      <GridItemFlex>
        {icon && (
          <IconWrapper>
            <Icon name={icon} size="large" color={colors.core01} />
          </IconWrapper>
        )}
        {heading && <Heading>{heading}</Heading>}
        <LeftAlignTextOnMobile>
          <FieldGrid alignLeft={alignLeft} gridItemProps={fieldGridItemProps}>
            <FieldInfo alertBody={alertBody}>
              {useFieldSet ? (
                <>
                  {introductoryText && <RichTextWithMargins html={introductoryText} />}
                  <Legend id={labelId} {...labelProps}>
                    {label}
                  </Legend>
                </>
              ) : (
                <Label id={labelId} htmlFor={childId} {...labelProps}>
                  {label}
                </Label>
              )}
              {explanatoryText && (
                <ExplanatoryText id={explanatoryTextId}>
                  {explanatoryText}
                </ExplanatoryText>
              )}
              {tooltip && <Tooltip {...tooltip} id={tooltipId} />}
            </FieldInfo>
            {React.cloneElement(children, {
              'aria-describedby': `${
                errorText || warningText ? errorId : ''
              } ${explanatoryTextId}`,
              'aria-invalid': !!errorText,
            })}
          </FieldGrid>
        </LeftAlignTextOnMobile>
      </GridItemFlex>
    </ErrorContainer>
  );
};

export default applyComponentTheme(FieldWithOptionalIconAndHeading, 'Field');
