import Image from '@rsa-digital/evo-shared-components/components/Image';
import InfoBanner from '@rsa-digital/evo-shared-components/components/InfoBanner';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const StyledInfoBanner = styled(InfoBanner)<{ children?: React.ReactNode }>`
  && {
    margin-top: 0;
  }
`;

export const StyledImage = styled(Image)`
  height: ${spacing(4)};
  width: auto;
  margin-bottom: ${spacing(2)};
`;
