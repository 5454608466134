import React from 'react';
import { Product } from 'helpers/productHelpers';
import {
  ChartText,
  ChartTextContainer,
  DonutChartContainer,
  DonutRing,
  DonutSegment,
  DonutSegmentBasic,
  FullSizedSvg,
} from './styles';
import { DonutChartWrapper } from '../ClassicPremierProductSummarySection/styles';

type DonutChartProps = {
  coveredClaimsPercentage: number;
  chartText: string;
  product: string;
};

const DonutChart: React.FC<DonutChartProps> = ({
  coveredClaimsPercentage,
  chartText,
  product,
}) => {
  // Circumference needs to be 100 for the cover percentage to work nicely
  const radius = 100 / (Math.PI * 2);

  return (
    <DonutChartWrapper>
      <DonutChartContainer>
        {/* 34 is the biggest width/height value before the viewbox starts clipping the chart */}
        <FullSizedSvg viewBox="0 0 34 34">
          <DonutRing r={radius} cx="50%" cy="50%" />
          {product === Product.Basic ? (
            <DonutSegmentBasic
              r={radius}
              cx="50%"
              cy="50%"
              radius={radius}
              percentageCovered={coveredClaimsPercentage}
            />
          ) : (
            <DonutSegment
              r={radius}
              cx="50%"
              cy="50%"
              radius={radius}
              percentageCovered={coveredClaimsPercentage}
            />
          )}
        </FullSizedSvg>
        <ChartTextContainer>
          <ChartText html={chartText} />
        </ChartTextContainer>
      </DonutChartContainer>
    </DonutChartWrapper>
  );
};

export default DonutChart;
