import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import React, { MouseEventHandler } from 'react';
import { PageTitle, trackRichTextLinkClick } from 'helpers/eventTracking';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import { replacePlaceholdersPlainText } from 'helpers/placeholders/replaceCsPlaceholders';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { LightRichText, StyledSpanLarge } from './styles';
import {
  ButtonLinkContainer,
  StyledButtonLink,
  StyledSpan,
  SummaryOfCoverSection,
} from '../styles';

type VetFeeLimitSectionProps = {
  vetFeeLimitText: string;
  vetFeeLimit: string;
  forEachText: string;
  optionalOrText?: string;
  coverDurationText: string;
  treatmentCostLink?: string;
  treatmentCostOnClick: MouseEventHandler;
  pageTitle: PageTitle;
};
const VetFeeLimitSection: React.FC<VetFeeLimitSectionProps> = ({
  vetFeeLimitText,
  vetFeeLimit,
  forEachText,
  optionalOrText,
  coverDurationText,
  treatmentCostLink,
  treatmentCostOnClick,
  pageTitle,
}) => {
  const quote = useCurrentQuote();
  const plainTextPlaceholderReplacer = replacePlaceholdersPlainText(
    quotePlaceholders,
    quote
  );

  return (
    <SummaryOfCoverSection>
      <GridItem desktop={6} tabletLandscape={6} tabletPortrait={6}>
        <StyledSpan>{plainTextPlaceholderReplacer(vetFeeLimitText)}</StyledSpan>
        <StyledSpanLarge>{vetFeeLimit}</StyledSpanLarge>
        <StyledSpan>{forEachText}</StyledSpan>
        {optionalOrText && <StyledSpanLarge>{optionalOrText}</StyledSpanLarge>}
        <LightRichText
          html={coverDurationText}
          onLinkClick={trackRichTextLinkClick(pageTitle)}
        />
        {treatmentCostLink && (
          <ButtonLinkContainer>
            <StyledButtonLink
              data-cy="treatmentCostModalButton"
              onClick={treatmentCostOnClick}
              color={colors.core01}>
              {treatmentCostLink}
            </StyledButtonLink>
          </ButtonLinkContainer>
        )}
      </GridItem>
    </SummaryOfCoverSection>
  );
};

export default VetFeeLimitSection;
